import React from 'react';
import { CategorySlug } from '../types';
export const designPreferenceTest = {
    slug: 'design-preference-test',
    title: 'Design Preference Survey Test Template | Ballpark',
    metaDescription: 'Measure which design option is preferred by your users in the design preference survey test template. Great for narrowing down a version of a design.',
    heading: 'Design preference test template',
    subheading: 'Multiple choice, but with designs and images',
    categories: [
        CategorySlug.Popular,
        CategorySlug.Basics,
        CategorySlug.Research,
    ],
    body: React.createElement(DesignPreferenceTestBody, null),
    templateUUID: 'ut_7b9b7483-c795-4828-a9e1-3c0284b1dbd3',
    recordLink: 'https://app.ballparkhq.com/record/7b9b7483-c795-4828-a9e1-3c0284b1dbd3',
    accordionTitle: 'Image preference test FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'Should low-level questions be avoided?',
            answerParagraphs: [
                'High-level questions are certainly more specific and easier to answer, but low-level questions can’t necessarily be avoided. When asking low-level questions (for example, asking recipients to choose a website design), try to focus on one screen at a time; or if testing an entire user flow, make it clear that you’d like feedback on the flow specifically and not the overall design. Specific questions are better.',
            ],
        },
        {
            id: 2,
            question: 'Must we test our own design(s)?',
            answerParagraphs: [
                'Not necessarily, no. When analyzing the competition (i.e. doing competitor analysis), we can use image preference tests to see which competitor’s design is the best. This tells us where to draw inspiration from and reveals any mistakes we’ll want to avoid when creating our own design.',
            ],
        },
    ],
};
function DesignPreferenceTestBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "A design preference test is essentially a multiple-choice question where the choices are designs or images. Specifically, this test can be used to find out which version of a design is better."),
        React.createElement("h3", null, "Design preference tests can be used to answer:"),
        React.createElement("ul", null,
            React.createElement("li", null, "High-level questions such as \u201CWhich design concept do you think is easier to use?\u201D"),
            React.createElement("li", null, "Mid-level questions such as \u201CWhich logo do you think is more memorable for our food delivery brand?\u201D"),
            React.createElement("li", null, "Low-level questions such as \u201CWhich website design do you like the most?\u201D"),
            React.createElement("li", null, "You can also use it to test your competitor products against each other or your own"))));
}
