import React from 'react';
import { CategorySlug } from '../types';
export const prototypeTest = {
    slug: 'prototype-test',
    title: 'Prototype Test Template | Ballpark',
    metaDescription: 'Identify usability improvements with this prototype testing template. Generate heatmaps, screen recordings and see how users interact with your designs.',
    heading: 'Prototype test template',
    subheading: 'Measure the usability of user flows or task flows and gain stakeholder feedback',
    categories: [CategorySlug.DesignAndProduct, CategorySlug.PrototypeTesting],
    body: React.createElement(PrototypeTestBody, null),
    templateUUID: 'ut_83e70632-bc77-412e-b645-b2f83fd6e6ff',
    recordLink: 'https://app.ballparkhq.com/record/83e70632-bc77-412e-b645-b2f83fd6e6ff',
    accordionTitle: 'Prototype test FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'Does prototype testing discover or diagnose problems?',
            answerParagraphs: [
                'Both. Prototype testing involves recording users’ audio, video and screens as they attempt to complete a task flow. After failing or succeeding at this, they can share their thoughts, feelings and feedback on the design. This may not result in a solid diagnosis, however, these insights combined with quantitative insights such as success rate and other qualitative insights can result in a diagnosis that you can feel confident about.',
                'Prototype testing can then be used summatively to validate a solution (and thus confirm the diagnosis).',
            ],
        },
        {
            id: 2,
            question: 'How do you create a prototype?',
            answerParagraphs: [
                'Creating a prototype is pretty simple yet important. It all starts with an idea and then manifesting a rough representation on paper or in a prototyping tool, such as Marvel. Through prototype testing and gathering continuous feedback you should end up with a perfect prototype which mimics its real-world application.',
            ],
        },
        {
            id: 3,
            question: 'What makes a good prototype?',
            answerParagraphs: [
                'A good prototype should give you confidence that users will interact with your product the way you wanted them to. It needs to mimic its real-world functionality as closely as possible in order to help you better understand the user experience and spot where the necessary changes are.',
            ],
        },
        {
            id: 4,
            question: 'How realistic should a prototype be?',
            answerParagraphs: [
                'The more realistic a prototype is, the more concise the feedback will be, and this is definitely something to aspire to as the prototype matures. That being said, you’ll probably want to take an iterative approach to prototype testing, testing low-fidelity prototypes as well as mid and high-fidelity prototypes so that designs are constantly being formed by feedback.',
            ],
        },
    ],
};
function PrototypeTestBody() {
    return (React.createElement("div", null,
        React.createElement("h3", null, "What is a prototype?"),
        React.createElement("p", null, "A prototype in simple terms is any version of your product before its final stage - a live mockup if you will!"),
        React.createElement("p", null, "Prototypes can vary in complexity. Low-fidelity prototypes, such as sketches, are generally used for testing concepts. Whereas high-fidelity prototypes are great for testing fully functional interactive prototypes allowing you to address any obstacles in the workflow and ensure the prototype aligns with your business goals."),
        React.createElement("p", null, "Many designers will create multiple variations of a prototype, all based on testing feedback, before they land on one that closely resembles the finishing goal for launch. And prototype testing is how they get there!"),
        React.createElement("h3", null, "What is prototype testing?"),
        React.createElement("p", null, "As soon as your first prototype is ready, you want to get it in front of your target audience as soon as possible and that\u2019s why prototype testing (sometimes referred to as performance testing or usability benchmarking) should play a vital part in your design process - not only at the beginning but throughout!"),
        React.createElement("p", null, "Prototype testing involves testing your prototype with real-world users to assess its viability during the early stages of the development journey. Sharing your prototype with users and asking insightful prototype user testing questions to gather user feedback early and often is the key to a successful launch. You wouldn\u2019t want to publicly launch your product before you\u2019re 100% confident that it\u2019s fully operational and solves the real-world problem you intended it to, would you?"),
        React.createElement("p", null, "Essentially, the goal of prototype testing is to validate your design choices, recognise where to make improvement by tackling problems, and ensure your product meets the needs and expectations of your users."),
        React.createElement("h3", null, "Why is prototype testing important?"),
        React.createElement("p", null, "If you want an idea of just how well a product will perform in the market before it actually performs then prototype testing is your answer. We\u2019re pretty sure there\u2019s not a designer out there that doesn\u2019t see the value in prototype testing - you just have to make sure to ask the right prototype user testing questions."),
        React.createElement("p", null, "Prototype testing has many benefits:"),
        React.createElement("ol", null,
            React.createElement("li", null,
                React.createElement("b", null, "Testing hypotheses;"),
                " you can rapidly collect real-world user perspectives to get proof of concept and refine ideas before launching confidently."),
            React.createElement("li", null,
                React.createElement("b", null, "Identify design improvements;"),
                " prototype testing gives you the chance to spot errors in the development cycle and roll out a fix to avoid receiving negative feedback once live."),
            React.createElement("li", null,
                React.createElement("b", null, "Save your company lots of time and money;"),
                " they say prevention is better than a cure and that definitely rings true for design. Catching a bug or design flaw in the design phase is much cheaper than in later phases of development."),
            React.createElement("li", null,
                React.createElement("b", null, "Stakeholder buy-in;"),
                " the combination of qualitative and quantitative research that prototype testing gathers provides convincing feedback to back up design decisions, ensure internal commitment towards the product/service and drive solution ownership.")),
        React.createElement("h3", null, "When should you conduct a prototype test?"),
        React.createElement("p", null, "With low-fidelity prototypes the perfect time to test is right after completing the design. Once you\u2019re on your way with your design and have made updates based on user test data, it\u2019s time to carry out your high-fidelity prototype testing."),
        React.createElement("p", null, "By testing your prototype with customers in the very early stages of the design process it means you can gain their initial reactions and insights to point you in the right direction. But it shouldn\u2019t stop there. You\u2019ll want to continue doing prototype testing right up until launch, bringing your users along the development journey with you and taking onboard their feedback before developing a perfectly refined final product."),
        React.createElement("p", null, "Therefore, an ongoing prototype testing process is best."),
        React.createElement("h3", null, "What types of data does a prototype test yield?"),
        React.createElement("p", null, "Prototype testing yields various qualitative and quantitative insights. Generated heatmaps and screen recordings can yield qualitative insights without researchers even having to moderate the user tester\u2019s session. Additionally, quantifiable metrics such as \u2018success rate\u2019 and \u2018time to completion\u2019 are useful for tracking improvement over time."),
        React.createElement("h3", null, "3 great ways to use our prototype test template:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Detect issues with user/task flows"),
            React.createElement("li", null, "Diagnose issues with user/task flows (to an extent)"),
            React.createElement("li", null, "Quantitatively track improvement over time")),
        React.createElement("h3", null, "What steps are in the Ballpark prototype test template?"),
        React.createElement("p", null, "The prototype test template helps you assess the usability and messaging within a prototype. Users are prompted to use a prototype and complete tasks, then answer a series of prototype user testing questions based on their experience."),
        React.createElement("p", null, "Using a rating scale users can score how easily it was to complete tasks during the prototype test. This is an example of a semantic differential scale which is arguably the most reliable and easiest way to understand user highs and lows in terms of their emotional reaction towards a prototype. The quantitive data recorded here can be used to create graphs or charts to validate the design and capture stakeholder buy-in."),
        React.createElement("p", null, "Following the successful (or possibly not so successful) completion of the test, the user is then asked a series of prototype user testing questions based on their experience interacting with the prototype."),
        React.createElement("p", null, "Then there\u2019s an open-ended free text collection question asking the user to explain how they would make the booking experience better and/or easier. This gives the user the freedom to creatively discuss their feelings, attitudes, and views towards your prototype. You may actually be shocked and surprised by the depth of the insights they provide and their answers could inspire your marketing and branding campaigns."),
        React.createElement("p", null, "On the subject of marketing, the final prototype user testing question asks the participant to choose their preferred marketing tagline for the app."),
        React.createElement("h3", null, "And that\u2019s it!"),
        React.createElement("p", null, "As with all the templates available in Ballpark, they are customisable so you can reorder or add as many additional questions as you like.")));
}
