import { __rest } from "tslib";
import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import { cn } from './utils';
function All(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return React.createElement(Comp, Object.assign({ className: cn('absolute inset-0', className) }, rest));
}
function Tl(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return React.createElement(Comp, Object.assign({ className: cn('absolute left-0 top-0', className) }, rest));
}
function Tc(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return (React.createElement(Comp, Object.assign({ className: cn('absolute left-1/2 top-0 -translate-x-1/2', className) }, rest)));
}
function Tr(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return React.createElement(Comp, Object.assign({ className: cn('absolute right-0 top-0', className) }, rest));
}
function Bl(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return (React.createElement(Comp, Object.assign({ className: cn('absolute bottom-0 left-0', className) }, rest)));
}
function Bc(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return (React.createElement(Comp, Object.assign({ className: cn('absolute bottom-0 left-1/2 -translate-x-1/2', className) }, rest)));
}
function Br(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return (React.createElement(Comp, Object.assign({ className: cn('absolute bottom-0 right-0', className) }, rest)));
}
function Center(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return (React.createElement(Comp, Object.assign({ className: cn('absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2', className) }, rest)));
}
function Lc(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return (React.createElement(Comp, Object.assign({ className: cn('absolute left-0 top-1/2 -translate-y-1/2', className) }, rest)));
}
function Rc(props) {
    const { className, asChild } = props, rest = __rest(props, ["className", "asChild"]);
    const Comp = asChild ? Slot : 'div';
    return (React.createElement(Comp, Object.assign({ className: cn('absolute right-0 top-1/2 -translate-y-1/2', className) }, rest)));
}
export const Pin = {
    All,
    Bc,
    Bl,
    Br,
    Center,
    Tc,
    Tl,
    Tr,
    Lc,
    Rc,
};
