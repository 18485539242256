import React from 'react';
import { CategorySlug } from '../types';
export const multipleChoiceQuestion = {
    slug: 'multiple-choice-question',
    title: 'Multiple Choice Survey Test Template | Ballpark',
    metaDescription: 'See how multiple choice questions can be used in surveys to guide users with a choice of answers with this survey test template.',
    heading: 'Multiple-choice question template',
    subheading: 'A question with multiple locked answers',
    categories: [CategorySlug.Basics, CategorySlug.Research],
    body: React.createElement(MultipleChoiceQuestionBody, null),
    templateUUID: 'ut_7e840c87-9099-4b5a-be35-625d0db139ce',
    recordLink: 'https://app.ballparkhq.com/record/7e840c87-9099-4b5a-be35-625d0db139ce',
    accordionTitle: 'Multiple-choice question FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'When should multiple-choice questions be avoided?',
            answerParagraphs: [
                "Multiple-choice questions should be avoided when you're unsure about the range of answers that respondents might want to choose, since the list of choices are locked and might not reflect what they want to say.",
                'In this scenario, an open-ended question might be better (where respondents can convey their answer in as many words as they feel is necessary as opposed to being limited to locked multiple-choice answers).',
            ],
        },
        {
            id: 2,
            question: 'Can multiple-choice questions have more than one answer?',
            answerParagraphs: [
                'Yes. If you want respondents to be able to choose more than one answer, you can enable multi-selection.',
            ],
        },
    ],
};
function MultipleChoiceQuestionBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "Multiple-choice questions ask respondents to choose one answer from a selection of locked answers, or any number of answers if multiple selections are allowed."),
        React.createElement("h3", null, "3 great ways to use our multiple-choice question template"),
        React.createElement("p", null, "Multiple-choice questions are useful for making democratic decisions that otherwise might require a lot of debate, as respondents can answer them easily and quickly. Multiple-choice questions can be handy in a variety of scenarios; here are three examples:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Ask respondents to nominate the next feature that\u2019ll be built (from a list of feature requests)"),
            React.createElement("li", null, "Ask respondents which section of an app or website could be improved (e.g. signup, search, booking)"),
            React.createElement("li", null, "Ask respondents demographical questions with only few answers (e.g. \u201CWhich mobile OS do you use?\u201D)"))));
}
