import React from 'react';
import { ClockAlertIcon, EarthIcon, PeopleBehindIcon, TargetIcon, } from '@marvelapp/ballpark-icons';
import { pluralize } from '@marvelapp/core';
import { BtwText } from '../BtwText';
import { BtwTooltip } from '../BtwTooltip';
import { Stack } from '../Stack';
export const OrderFilterSummary = function OrderFilterSummary({ age, country, duration, numberOfResponses, studySpecificFilters, studyType, }) {
    return (React.createElement(Stack, { className: "flex-1 divide-y divide-gray-600/10 sm:h-full sm:overflow-y-scroll", width: "full" },
        React.createElement(MainTargeting, { age: age, country: country, duration: duration, numberOfResponses: numberOfResponses, studyType: studyType }),
        !!(studySpecificFilters === null || studySpecificFilters === void 0 ? void 0 : studySpecificFilters.length) && (React.createElement(Stack, { className: "divide-y divide-gray-600/10 ", width: "full" }, studySpecificFilters.map(([id, { rowIcon, name, tooltipText, options }]) => (React.createElement(ListItem, { className: "p-6", icon: rowIcon, key: id, label: name, tooltipText: tooltipText }, options.map((option) => (React.createElement(BtwText, { key: option.id, size: "13", variant: "primary" }, option.name))))))))));
};
const MainTargeting = function MainTargeting({ age, country, duration, numberOfResponses, studyType, }) {
    return (React.createElement(Stack, { className: "p-6", gap: "5", width: "full" },
        React.createElement(ListItem, { icon: React.createElement(TargetIcon, null), label: "Study focus" },
            React.createElement(BtwText, { size: "13", variant: "primary", weight: "medium" }, studyType)),
        React.createElement(ListItem, { icon: React.createElement(PeopleBehindIcon, null), label: "Number of responses" },
            React.createElement(BtwText, { size: "13", variant: "primary", weight: "medium" }, numberOfResponses)),
        React.createElement(ListItem, { icon: React.createElement(ClockAlertIcon, null), label: "Study duration" },
            React.createElement(BtwText, { size: "13", variant: "primary", weight: "medium" },
                duration,
                " ",
                pluralize(duration, 'minute'))),
        country && (React.createElement(ListItem, { icon: React.createElement(EarthIcon, null), label: "Country" },
            React.createElement(BtwText, { size: "13", variant: "primary", weight: "medium" }, country))),
        age && (React.createElement(ListItem, { icon: React.createElement(PeopleBehindIcon, null), label: "Age" }, age))));
};
export const ListItem = function ListItem({ className, children, icon, label, tooltipText, }) {
    return (React.createElement(Stack, { className: className, "data-testid": "summary-item", direction: { initial: 'col', md: 'row' }, width: "full", gap: "4" },
        React.createElement(Stack, { align: "center", className: "[&>svg]:size-4.5 flex-1 [&>svg]:mt-px [&>svg]:shrink-0", direction: "row", gap: "2" },
            icon,
            React.createElement(BtwTooltip, { content: tooltipText, "data-testid": "filter-info-tooltip" },
                React.createElement("div", null,
                    React.createElement(BtwText, { "data-testid": "summary-item-label", size: "13", className: "line-clamp-1 cursor-pointer text-left" }, label)))),
        React.createElement(Stack, { className: "flex-1", "data-testid": "summary-item-value", gap: "2" }, children)));
};
