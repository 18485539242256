import { getFormattedImageUrl } from './getFormattedImageUrl';
export function getThumbnail(imageSrc, width = 200) {
    return getFormattedImageUrl({ url: imageSrc, crop: false, width }, 'freetls.fastly.net/canvas');
}
export function getThumbnail2x(imageSrc, width = 200) {
    return getFormattedImageUrl({
        url: imageSrc,
        crop: false,
        width,
        dpr: 2,
    }, 'freetls.fastly.net/canvas');
}
export function getImage(imageSrc, width = window.screen.width) {
    return getFormattedImageUrl({ url: imageSrc, crop: false, width }, 'freetls.fastly.net/canvas');
}
export function getImage2x(imageSrc, width = window.screen.width) {
    return getFormattedImageUrl({ url: imageSrc, crop: false, width, dpr: 2 }, 'freetls.fastly.net/canvas');
}
