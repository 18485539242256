import { __rest } from "tslib";
import React from 'react';
import { Combobox } from './Combobox';
import { Stack } from './Stack';
import { cn } from './utils';
function Trigger(props) {
    const { selectedLanguage, className } = props, rest = __rest(props, ["selectedLanguage", "className"]);
    return (React.createElement(Combobox.Trigger, Object.assign({ className: cn('w-full', className), "data-testid": "language-select-trigger", hasPlaceholder: true, size: "intermediate", truncate: true }, rest),
        React.createElement(Stack, { align: "center", direction: "row", gap: "2" },
            selectedLanguage.flag({ width: 16, height: 16 }),
            selectedLanguage.name)));
}
function Content(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(Combobox.Content, Object.assign({ className: "max-h-64", modal: true, placeholder: "Search for options..." }, rest),
        React.createElement(Combobox.Empty, null),
        children));
}
function Item(props) {
    const { language, selectedLanguage, 'data-testid': testId } = props, rest = __rest(props, ["language", "selectedLanguage", 'data-testid']);
    return (React.createElement(Combobox.Item, Object.assign({ "data-testid": testId !== null && testId !== void 0 ? testId : `language-option-${language.id}`, keywords: [language.id, ...language.alternatives, language.name], selected: selectedLanguage.id === language.id, value: language.id }, rest),
        React.createElement(Stack, { align: "center", direction: "row", gap: "2" },
            language.flag({ width: 16, height: 16 }),
            language.name)));
}
export const LanguageSelect = {
    Content,
    Item,
    Root: Combobox.Root,
    Trigger,
};
