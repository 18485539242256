import React from 'react';
import { CategorySlug } from '../types';
export const productOnboardingSurvey = {
    slug: 'product-onboarding-survey',
    title: 'Product Onboarding Survey Test Template | Ballpark',
    metaDescription: 'Discover how you can improve your product onboarding with our product survey template. Gain actionable insights that lead to better product experiences.',
    heading: 'Product onboarding template',
    subheading: 'Acquire product insights from potential customers',
    categories: [
        CategorySlug.Popular,
        CategorySlug.ProductFeedback,
        CategorySlug.DesignAndProduct,
    ],
    body: React.createElement(ProductOnboardingBody, null),
    templateUUID: 'ut_c3a81ced-7df9-4481-a359-0948668ab84e',
    recordLink: 'https://app.ballparkhq.com/record/c3a81ced-7df9-4481-a359-0948668ab84e',
    accordionTitle: 'Product onboarding survey FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'What other questions should be asked in a product onboarding survey?',
            answerParagraphs: [
                'Besides the insights mentioned above, you’ll want to acquire some additional context such as the respondents job role, and also their email address so that they can be followed up with. Other than that, any questions that gauge product sentiment, highlight good or missing features, or tell us something about the competition are good questions.',
                'The survey shouldn’t be long, so choose your questions wisely.',
            ],
        },
        {
            id: 2,
            question: 'Can product onboarding surveys actually improve onboarding?',
            answerParagraphs: [
                'Yes, especially when paired with the “What is your job role?” question. The goal of a product onboarding survey is not just to find out how potential customers feel about the product, but how they feel the product will help them specifically. These insights can be used to tailor onboarding experiences to specific roles.',
            ],
        },
    ],
};
function ProductOnboardingBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "Product onboarding surveys (sometimes referred to as customer onboarding surveys) are taken by potential customers after a sales call or free trial but before becoming a paying customer. The resulting insights can be used to create a better onboarding experience and product overall."),
        React.createElement("h3", null, "4 great ways to use our product onboarding survey template"),
        React.createElement("p", null, "Product onboarding surveys should be short, clear, and respondents should be able to answer the questions without needing to consult other team members. They can be used to:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Discover why respondents need the product"),
            React.createElement("li", null, "Find out how they discovered it"),
            React.createElement("li", null, "Learn how it could be better"),
            React.createElement("li", null, "Acquire quantitative insight such as how likely they are to recommend it (i.e. Net Promoter Score, or something similar)"))));
}
