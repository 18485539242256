import React from 'react';
import { GridFindIcon } from '@marvelapp/ballpark-icons';
import { FigmaIntegrationIcon, MarvelIntegrationIcon, SlackIntegrationIcon, } from '@marvelapp/ballpark-icons/src/integrationIcons';
import { BtwButton } from '../BtwButton';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { CtaMessage } from '../CtaMessage';
import { AppSidebar, SidebarNavItem, SidebarPanel } from '../Sidebar';
import { Stack } from '../Stack';
import { SettingsHeader } from './SettingsHeader';
export var ConnectedApps;
(function (ConnectedApps) {
    ConnectedApps["figma"] = "Figma";
    ConnectedApps["marvel"] = "Marvel";
    ConnectedApps["slack"] = "Slack";
})(ConnectedApps || (ConnectedApps = {}));
function ConnectedAppsSettingsPage(props) {
    const { connectedApps = [ConnectedApps.figma] } = props;
    return (React.createElement(Stack, { width: "full", className: "h-full", direction: "row" },
        React.createElement(AppSidebar, { initialMenuItem: SidebarNavItem.Settings_ConnectedApps, initialActivePanel: SidebarPanel.Settings }),
        React.createElement(Stack, { width: "full", className: "h-full overflow-y-scroll" },
            React.createElement(SettingsHeader.Container, null,
                React.createElement(SettingsHeader.Title, null, "Connected apps"),
                React.createElement(SettingsHeader.Actions, null,
                    React.createElement(BtwButton, { size: "sm" }, "Request an integration"))),
            React.createElement(Stack, { width: "full", gap: "8", className: "mx-auto h-full max-w-6xl flex-1 overflow-y-scroll" }, connectedApps.length > 0 ? (React.createElement(Stack, { width: "full", className: "p-8", gap: "4", direction: { initial: 'col', xl: 'row' }, wrap: "wrap", align: "stretch" }, connectedApps.map((app) => {
                const connectedApp = ConnectedAppsList.find((item) => item.name === app);
                if (connectedApp) {
                    return (React.createElement(Stack, { direction: "row", align: "center", gap: "5", className: "ease-smooth relative w-full rounded-xl p-6 ring-1 ring-gray-600/10 transition-all duration-1000 only:w-full hover:bg-gray-50 xl:w-[calc(50%_-_8px)]" },
                        React.createElement(Stack, { className: "rounded-xl p-2 ring-1 ring-inset ring-gray-600/10", style: {
                                backgroundColor: getBackgroundColor(connectedApp.name),
                            } }, connectedApp.icon),
                        React.createElement(Stack, { width: "full", gap: "1" },
                            React.createElement(BtwHeading, { size: "sm" }, connectedApp.name),
                            React.createElement(BtwText, { size: "13" }, connectedApp.description)),
                        React.createElement(Stack, null,
                            React.createElement(BtwButton, { variant: "danger", size: "intermediate" }, "Disconnect"))));
                }
                return null;
            }))) : (React.createElement(Stack, { width: "full", align: "center", justify: "center", className: "h-full" },
                React.createElement(CtaMessage.Root, { size: "sm" },
                    React.createElement(CtaMessage.Header, { icon: React.createElement(GridFindIcon, null) },
                        React.createElement(CtaMessage.Heading, null, "No connected apps\u2026 yet!"),
                        React.createElement(CtaMessage.Description, null, "You have not added any integrations!")))))))));
}
const ConnectedAppsList = [
    {
        name: ConnectedApps.figma,
        description: 'Embed prototypes or single screens in your research projects',
        icon: React.createElement(FigmaIntegrationIcon, null),
    },
    {
        name: ConnectedApps.marvel,
        description: 'Embed your Marvel prototypes in your research projects',
        icon: React.createElement(MarvelIntegrationIcon, null),
    },
    {
        name: ConnectedApps.slack,
        description: 'Notify a channel whenever a new response is received in your Ballpark project',
        icon: React.createElement(SlackIntegrationIcon, null),
    },
];
function getBackgroundColor(app) {
    switch (app) {
        case ConnectedApps.figma:
            return '#1C1C1C';
        case ConnectedApps.marvel:
            return '#1FB6FF';
        default:
            return '#ffffff';
    }
}
export { ConnectedAppsSettingsPage };
