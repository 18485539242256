import React from 'react';
import styled from '@marvelapp/styled';
import { CategorySlug } from '../types';
export const websiteUsabilityTest = {
    slug: 'website-usability-test-template',
    title: 'Website Usability Test Template | Ballpark',
    metaDescription: 'Test your marketing site or web app. Discover whether features, messaging, pricing or conversion are intuitive. Record feedback by video, audio or survey responses.',
    heading: 'Website usability test template',
    subheading: 'Gain insight into your live website’s usability (and more)',
    categories: [CategorySlug.WebsiteTesting, CategorySlug.Marketing],
    body: React.createElement(WebsiteUsabilityTestBody, null),
    templateUUID: 'ut_fd4368c6-2ad8-4f0d-a4fb-b99919910585',
    recordLink: 'https://app.ballparkhq.com/record/fd4368c6-2ad8-4f0d-a4fb-b99919910585',
    accordionTitle: 'Website usability test template FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'How does the website usability test actually work?',
            answerParagraphs: [
                'First, respondents are prompted by their web browser to give the web browser access to their camera and microphone as well as give permission to record their screen. Those same permissions allow Ballpark to save the data and pass it on to you for research purposes. Once the correct permissions have been granted, the test/questionnaire can begin.',
                'Eventually (perhaps after some preliminary questioning), the live website will open in a new tab and respondents can then begin attempting to complete the first website test, which’ll be recorded.',
                'Once respondents are finished trying to complete the task, they can either return to the test/questionnaire or they can close the tab (which effectively does the same thing) to move forward.',
            ],
        },
        {
            id: 2,
            question: 'Is the website usability test easy for respondents to take?',
            answerParagraphs: [
                'Yes. To start off, respondents will need to give their web browser permission to record their face, voice, and screen. A visually obvious web browser prompt will get this straightforward process started, however, macOS users might also need to give permission at the operating system level, which would require respondents to restart their web browser before attempting the test.',
                'Again, this is super easy and completely non-destructive as long as the web browser saves the respondent’s tab (which most do). If the tab wasn’t saved, the respondent can start over by clicking on their invite link.',
            ],
        },
        {
            id: 3,
            question: 'What should I do with website usability test data?',
            answerParagraphs: [
                'Website usability tests are very qualitative, providing an abundance of useful insights that you can use to improve your live website’s usability (and more). To synthesize this data you’ll want to look into affinity mapping, which in this case would involve grouping feedback that appears to be similar and then ordering the groups by size, revealing which concerns were raised by respondents the most.',
                'Also, it might be useful to estimate the severity of the concerns in addition to how easy they would be to address.',
                'All of this combined can help product teams prioritize what to focus on. Affinity mapping also simplifies the feedback, which is handy because it comes in so many different forms (written, spoken, visual, and quantitative).',
            ],
        },
        {
            id: 4,
            question: 'How can I compare my website to that of my competitors?',
            answerParagraphs: [
                'Since we’re talking about live websites, there’s no reason why you couldn’t carry out website testing with your competitors. As long as they also have live websites that are comparable to yours, you could test them all and then compare the results. However, to test a competitor’s website you’ll want to use our competitor website usability test template, which is essentially the same as this template except that the questions have been slightly reworded.',
            ],
        },
    ],
};
function WebsiteUsabilityTestBody() {
    return (React.createElement("div", null,
        React.createElement(FirstHeader, null, "What is a website usability test?"),
        React.createElement("p", null, "A website usability test is a test and questionnaire that primarily enables you to assess the usability of your live website. Instead of testing a prototype that\u2019s \u2018like the real thing\u2019, website usability tests enable you to test the actual real thing."),
        React.createElement("h3", null, "Why are website usability tests used?"),
        React.createElement("p", null, "Website usability tests are used for two reasons."),
        React.createElement("p", null, "Firstly, they\u2019re used to collect real feedback on live websites, enabling you to assess certain aspects of the usability (e.g. reliability and performance) that would be impossible to assess when testing with prototypes. This is in addition to all other aspects of usability, of course."),
        React.createElement("p", null, "Secondly, it\u2019s not uncommon for website projects to have no up-to-date prototypes or even no prototypes at all. If you\u2019ve ever been the first designer in a previously all-dev product team, you\u2019ll be very familiar with the scenario where website usability tests are not only a great choice, but the only choice anyway."),
        React.createElement("p", null, "You can also use the template for general website testing, gathering feedback on features, aesthetics, marketing, and so on, instead of usability or in addition to it. The template can be customized easily, so it\u2019s your call."),
        React.createElement("h3", null, "Who uses website usability tests?"),
        React.createElement("p", null, "Website usability tests are used by designers and design researchers that are looking for opportunities to improve live websites."),
        React.createElement("h3", null, "What types of data do website usability tests yield?"),
        React.createElement("p", null, "Website usability tests are mostly qualitative, resulting in extremely useful written and spoken feedback in addition to visual feedback consisting of face and screen recordings. Our website usability test template includes some quantitative questions and you can customize the template to include more."),
        React.createElement("p", null, "It\u2019s one of the most comprehensive website testing templates in terms of data yield."),
        React.createElement("h3", null, "What questions are in the competitor website usability test template?"),
        React.createElement("p", null, "The template starts off with a preliminary multiple-choice question designed to provide context to the respondent\u2019s answers. If needed, you can customize the question to turn it into a different type of question (as is the case with all questions in all Ballpark templates)."),
        React.createElement("p", null, "After that, respondents are asked to allow their face, voice, and screen to be recorded before being presented with the first website testing task (e.g. \u201CGo to our website and add a jar of Hot Garlic to your basket\u201D)."),
        React.createElement("p", null, "Next, after successfully (or unsuccessfully) completing the task, respondents are asked how it went (e.g. \u201CWere you able to add the jar of Hot Garlic to your basket?\u201D, a simple yes/no question that, again, can be customized)."),
        React.createElement("p", null, "The template then continues on with the same format: a website test followed by a short questionnaire consisting of qualitative and quantitative questions about the experience that they had."),
        React.createElement("p", null, "To wrap up, respondents are asked to rate how likely they are to recommend the website on a scale of 0-10 (which can optionally be synthesized into a Net Promoter Score), and to provide their email address so that you can follow up with them regarding the feedback that they gave (if needed)."),
        React.createElement("h3", null, "Some of the example questions in our paid search test template:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Have you heard of the brands in these adverts?"),
            React.createElement("li", null, "Which of these adverts would you click on and why?"),
            React.createElement("li", null, "How would you improve the following advert?"),
            React.createElement("li", null, "Beyond this advert, what additional information would you need to be confident to buy?"))));
}
const FirstHeader = styled.h3 `
  margin-top: 0px !important;
`;
