import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link } from '../Link';
import { CategorySlug } from '../types';
export const figmaInteractiveComponentTest = {
    slug: 'figma-interactive-component-test',
    title: 'Figma Component Test Template | Ballpark',
    metaDescription: 'Measure the usability of your Figma prototype on this interactive component test template. Create tasks, test copy and get feedback on your components.',
    heading: 'Figma interactive component test template',
    subheading: 'Get feedback on individual Figma components',
    categories: [CategorySlug.PrototypeTesting, CategorySlug.DesignAndProduct],
    body: React.createElement(FigmaInteractiveComponentTestBody, null),
    templateUUID: 'ut_b06e811f-0f63-407b-b0a4-f1861634064f',
    recordLink: 'https://app.ballparkhq.com/record/b06e811f-0f63-407b-b0a4-f1861634064f',
    accordionTitle: 'Figma interactive component test template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'How should I set up my Figma file for component testing?',
            answerParagraphs: [
                'If you’d like to get feedback on a Figma component, just make sure that it’s wrapped in a Frame (technically it doesn’t need to be an actual Figma Component) and you’ve tested any interactivity to make sure that it’s working.',
                'If you’d like to test multiple variations of the component, see the FAQ below.',
            ],
        },
        {
            id: 2,
            question: 'Can I create an A/B test with a Figma interactive component?',
            answerParagraphs: [
                'Yes, if testing two or more variations of the component, just make sure that the components appear side-by-side and are contained by a parent Frame. When linking your Figma file to the Figma interactive component test template, you’ll need to link to that specific parent Frame.',
            ],
        },
        {
            id: 3,
            question: 'How do I link my Figma prototype to the template?',
            answerParagraphs: [
                'When setting up/customizing the template, click on “Change prototype” and then paste in your Figma file share link when asked to do so.',
                'To get the correct share link from Figma, make sure that you have the right Frame selected before clicking on the “Share” button in the horizontal toolbar. Next, make sure that the “Link to selected frame” box is checked before clicking on “Copy link” (in the bottom-left corner of the modal) to copy the correct Figma share link to your clipboard.',
                'If testing with people outside of your organization (or more specifically, people that don’t have the correct permissions to view the Figma file), you’ll also want to make sure that the link is publicly available to anyone. Ideally, you’ll want to set the permissions to “Anybody with the link” “can view prototypes” only.',
            ],
        },
    ],
};
function FigmaInteractiveComponentTestBody() {
    return (React.createElement("div", null,
        React.createElement("h3", null, "What is a Figma interactive component test?"),
        React.createElement("p", null, "A Figma interactive component test prompts users to interact with (or just observe) components and then provide feedback on it by answering a series of questions."),
        React.createElement("h3", null, "4 great ways to use the Figma component test template?"),
        React.createElement("ul", null,
            React.createElement("li", null, "Create tasks based on your components to discover whether their core action is intuitive"),
            React.createElement("li", null, "Test different copy on the components to see which is more effective"),
            React.createElement("li", null, "Get opinions from your internal team on your design system and individual components"),
            React.createElement("li", null, "Ask testers for feedback on a wide range of questions about your component, including multiple choice and ratings")),
        React.createElement("h3", null, "How does Ballpark work with Interactive components?"),
        React.createElement("p", null, "Ballpark allows you to embed your Figma frame containing your components directly in your test, allowing you to gather feedback on the specific elements of your design system or interactions. That means you don\u2019t need to test an entire prototype flow, only the individual components."),
        React.createElement("h3", null, "Why are Figma interactive component tests used?"),
        React.createElement("p", null,
            "Figma interactive component tests are used for gathering feedback on the usefulness, accessibility, usability, and aesthetics of Figma components in a design or design system.",
            ' '),
        React.createElement("p", null,
            "While this doesn\u2019t replace",
            ' ',
            React.createElement(Link, { href: "https://ballparkhq.com/template/prototype-test" }, "prototype testing,"),
            ' ',
            "which is used to find out how well users are able to complete real-world tasks in the context of the problem space, Figma interactive component tests are incredibly useful for when you want users to focus on individual components and describe how effective they are at serving a general purpose such as searching, sorting, or calling to an action."),
        React.createElement("p", null, "In addition to this, prototype testing often requires a lot of planning and resources, and results in feedback that focuses more on the bigger picture rather than, for example, the visual affordance of a tap target."),
        React.createElement("p", null, "More specifically, designers can use Figma interactive component tests to check that a component\u2019s UI copy is clear and actionable, that the aesthetics appear visually appealing and feel on-brand, or that the component is even necessary for inclusion in the design or design system."),
        React.createElement("p", null, "Figma interactive component tests can still be task-based but on a much smaller scale, focusing more on the microinteractions within a component rather than entire interactive prototypes."),
        React.createElement("h3", null, "Who uses Figma interactive component tests?"),
        React.createElement("p", null,
            "Figma interactive component tests are used by designers to make sure that their design and design system components are as lean and as effective as they can be. The tests can be conducted with real users to find out how well the components are meeting user needs, or they can be conducted with stakeholders (usually other designers), which isn\u2019t as effective but is faster and will still yield useful insights.",
            ' '),
        React.createElement("p", null, "That being said, one particular use-case for carrying out Figma interactive component tests with designers is to evaluate whether or not a component should be included in a design system, taking into consideration its reusability, adaptability, and how ready it is for inclusion."),
        React.createElement("h3", null, "What types of data does the Figma interactive component test yield?"),
        React.createElement("p", null, "Figma interactive component tests are usually qualitative, asking open-ended questions about the component overall and then some more specific aspects of it such as how on-brand it is."),
        React.createElement("p", null, "If you\u2019d like to test multiple variations of a component (i.e. A/B testing or multivariate testing), you\u2019ll want to ask respondents which one they prefer, which\u2019ll naturally result in some quantitative data too."),
        React.createElement("p", null, "In addition to this, our template can also be customized to include as many extra questions as are needed (these can be open-ended questions, multiple-choice questions, rating scales, and so on depending on the type of feedback that you\u2019re looking for)."),
        React.createElement("h3", null, "What questions are in the Figma interactive component test template?"),
        React.createElement("p", null, "The first part of the template is the test itself where the component is shown to the respondent, which is actually just a Figma embed that links to a specific Frame. If you\u2019d like to utilize A/B testing or multivariate testing to test multiple variations of the component, the Figma embed can link to a parent Frame that displays the variations side-by-side. In this case, you\u2019ll want the first question to be \u201CWhich variation do you prefer?\u201D and the second question to be \u201CWhy did you choose that variation?\u201D. If the component doesn\u2019t have any variations then you\u2019ll want to dive straight into the overarching \u201CWhat do you think about this component?\u201D question."),
        React.createElement("p", null, "Next, the template focuses on how on-brand the component is using one open-ended question and, if utilizing A/B testing or multivariate testing, one \u201CWhich variation\u2026?\u201D question."),
        React.createElement("p", null, "After that, you can customize the template to ask about the usability, the aesthetics, whether or not the component should be included in the design system, or whatever you feel is relevant.")));
}
