import React from 'react';
import { Link } from '../Link';
import { CategorySlug } from '../types';
export const designFeedbackSurvey = {
    slug: 'design-feedback-survey',
    title: 'Design Feedback Survey Template | Ballpark',
    metaDescription: 'Discover how effective your designs are in communicating trust and simplicity.',
    heading: 'Design feedback survey template',
    subheading: 'See what users (or stakeholders) low-key think of your design',
    categories: [
        CategorySlug.Survey,
        CategorySlug.DesignAndProduct,
        CategorySlug.Popular,
    ],
    body: React.createElement(DesignFeedbackSurveyBody, null),
    templateUUID: 'ut_57643465-841e-46ac-b6d8-ba9524009514',
    recordLink: 'https://app.ballparkhq.com/record/57643465-841e-46ac-b6d8-ba9524009514',
    accordionTitle: 'Design feedback survey template FAQs ',
    accordionItems: [
        {
            id: 1,
            question: 'When should I use the design feedback survey template?',
            answerParagraphs: [
                'While design feedback surveys can be used at the low-fidelity stage, we recommend using them more frequently as your design matures into mid-fidelity and high-fidelity, enabling respondents to comment on the design’s aesthetic and other aspects of the design that are more developed at those stages.',
                'When it comes to low-fidelity design, it’s better to use design research methods that dive beyond the surface layer, saving design feedback surveys for mid- and high-fidelity design.',
            ],
        },
        {
            id: 2,
            question: 'Can I link my design feedback survey to a prototype?',
            answerParagraphs: [
                'Yes. When setting up your design feedback survey, you can link to a Figma prototype that respondents will be able to see and interact with before being presented with the survey questions. This differs from prototype tests in the way that prototype tests ask users to complete and leave feedback on specific tasks, whereas design feedback surveys are completely open-ended.',
            ],
        },
        {
            id: 3,
            question: 'How can I persuade people to complete my design feedback survey?',
            answerParagraphs: [
                'Design feedback surveys are really easy to set up and friendly to respondents. They aren’t very time consuming and there’s little pressure on respondents to give lengthy answers. When it comes to surveys, the real challenge is persuading people to complete them.',
                'Considering how quick and easy this survey is, try estimating how long it takes to complete it and conveying this to potential respondents. In addition, it might be beneficial to explain how useful their feedback could be and/or how useful other respondent’s feedback has been already.',
                'You could also send a reward (e.g. a voucher or some cool company swag) to some or all of the respondents, to add incentive.',
            ],
        },
    ],
};
function DesignFeedbackSurveyBody() {
    return (React.createElement("div", null,
        React.createElement("h3", null, "What is a design feedback survey?"),
        React.createElement("p", null, "A design feedback survey is a low-key design survey that asks respondents (which can be users or stakeholders) what they think of a design at the surface level. By \u2018surface level\u2019 we mean just by looking at it (or if you\u2019re presenting a functional prototype, then by playing around with it a little bit)."),
        React.createElement("p", null,
            "It\u2019s an open-ended design survey, which means that respondents can look at, interact with, and leave feedback on anything that they feel compelled to. This is what sets the survey apart from other types of design research such as",
            ' ',
            React.createElement(Link, { href: "https://ballparkhq.com/template/prototype-test", target: "_blank" }, "prototype testing,"),
            ' ',
            "where users are asked to complete and leave feedback on specific tasks."),
        React.createElement("h3", null, "Why are design feedback surveys used?"),
        React.createElement("p", null, "Whether you\u2019re designing an app, a website, or a brand, it\u2019s important to understand how it resonates with your audience, and the best way to do that is with a design feedback survey."),
        React.createElement("p", null, "Since design feedback surveys are open-ended, they give respondents the opportunity to open up about anything that they feel should be mentioned \u2014 the negative things and the positive things \u2014 no filter. For this reason, design feedback surveys are able to catch insights that many other types of design research can\u2019t."),
        React.createElement("p", null, "Design research doesn\u2019t need to be intensive or even pre-planned. In fact, design feedback surveying can be conducted almost spontaneously, rapidly providing new direction to product teams that feel like they\u2019re being too introspective. You could carry out design feedback surveying today."),
        React.createElement("h3", null, "Who uses design feedback surveys?"),
        React.createElement("p", null, "Design feedback surveys are used by designers to acquire design feedback. However, design researchers or anybody else with at least a little bit of knowledge on design surveying and synthesizing open-ended feedback can also use them, relaying any resulting insights to the designers."),
        React.createElement("h3", null, "What types of data do design feedback surveys yield?"),
        React.createElement("p", null, "Our design feedback survey template includes one qualitative question, an open-ended question where respondents can go into as much detail as they want about what they like and/or dislike about the design."),
        React.createElement("p", null, "The other questions are quantitative yes/no questions and rating scale questions about specific aspects of the design, revealing how effective those aspects appear to be. You can use these questions to track improvement over time."),
        React.createElement("h3", null, "What questions are in the design feedback survey template?"),
        React.createElement("p", null, "By default, our design feedback survey template asks respondents whether or not they think the design is visually appealing, and then what they liked and/or disliked about it."),
        React.createElement("p", null, "Next, respondents are asked to rate the design\u2019s aesthetic, navigation, and simplicity on a scale of 1 to 5."),
        React.createElement("p", null,
            "After that, respondents are asked how likely they would be to recommend the app or website to a friend or colleague on a scale of 0 to 10. The resulting data, once synthesized, can even be used to predict how the design might impact the brand\u2019s overall",
            ' ',
            React.createElement(Link, { href: "https://ballparkhq.com/template/nps-survey", target: "_blank" }, "Net Promoter Score."),
            ' '),
        React.createElement("p", null, "To wrap up, respondents are asked how trustworthy they believe the app or website to be on a scale ranging from \u201CExtremely likely\u201D to \u201CNot at all likely\u201D."),
        React.createElement("p", null, "You can customize the template to include any additional questions you\u2019d like to ask. For example, you might want to ask respondents about the copy, branding, or what they believe the app or website to be about. Design feedback surveys are great for finding out which aspects of a design are and aren\u2019t working, so feel free to customize the template to zone in on whatever you feel you need to know more about. You can also customize the template to edit or remove the questions that are already there."),
        React.createElement("p", null,
            "Feedback from design feedback surveys tend to reflect how respondents feel about the design at the surface level. To measure and observe how effectively users are able to actually complete specific tasks, you should use our",
            ' ',
            React.createElement(Link, { href: "https://ballparkhq.com/template/prototype-test", target: "_blank" }, "prototype testing,"),
            ' ',
            "instead.")));
}
