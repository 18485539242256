import React from 'react';
import { CategorySlug } from '../types';
export const qualityControlScreener = {
    slug: 'quality-control-screener',
    title: 'Quality Control Screener | Ballpark',
    metaDescription: 'This quality control screener template includes questions designed to filter out bots, AI, and inattentive respondents. These questions ensure the accuracy and reliability of survey data by identifying and excluding invalid responses.',
    heading: 'Quality Control Screener',
    subheading: 'This quality control screener template includes questions designed to filter out bots, AI, and inattentive respondents. ',
    categories: [CategorySlug.Screeners],
    body: React.createElement(QualityControlScreenerBody, null),
    templateUUID: 'ut_5c697ad4-a221-4e31-bf97-7a7b25fde360',
    recordLink: 'https://app.ballparkhq.com/record/5c697ad4-a221-4e31-bf97-7a7b25fde360',
    accordionTitle: '',
    accordionItems: [],
};
function QualityControlScreenerBody() {
    return (React.createElement("div", null,
        React.createElement("h3", null, "What is a Quality Control Question screener?"),
        React.createElement("p", null, "With issue of poor participant responses (straight-lining, rushing, nonsense answers) and the recent increase of AI / bots in research, it can be important to ensure that the data you're collecting is valid and reliable. We have protocols in place to ensure our data quality is exemplary, however we understand that some researchers always prefer to include a quality control question (QCQ) in their surveys."),
        React.createElement("p", null, "A Quality control screener helps identify respondents or bots who are not paying attention, providing random answers, or rushing through the survey without proper consideration. By including these questions, which typically have an obvious or straightforward answer, researchers can filter out low-quality responses. This ensures that the insights and conclusions drawn from the survey are based on accurate and thoughtful participant input."),
        React.createElement("p", null, "Furthermore, quality control questions contribute to the integrity of the survey results. They serve as a check against common issues such as respondent fatigue, inattentiveness, and fraudulent responses. By identifying and excluding problematic responses, researchers can maintain a higher standard of data quality, leading to more reliable and actionable outcomes."),
        React.createElement("h3", null, "When to Use a Quality Control Screener"),
        React.createElement("p", null, "Using a quality control screener is crucial when you need to ensure the validity and reliability of your survey data, especially in the presence of potential issues like poor participant responses, AI bots, and inattentive participants. Here\u2019s when you should consider using it:"),
        React.createElement("ol", null,
            React.createElement("li", null,
                React.createElement("b", null, "Ensuring Data Quality:"),
                " When facing straight-lining, rushing, or nonsense answers, QCQs help filter out these low-quality responses."),
            React.createElement("li", null,
                React.createElement("b", null, "Mitigating AI/Bot Responses:"),
                " With the rise of AI and bots in research, QCQs help identify and exclude non-human responses."),
            React.createElement("li", null,
                React.createElement("b", null, "Maintaining Data Integrity:"),
                " By including QCQs, researchers can check for attentiveness, reduce respondent fatigue, and avoid fraudulent responses, ensuring that survey results are reliable and actionable."))));
}
