import React from 'react';
import { AppleIcon, BagShoppingIcon, BuildingsIcon, ChecklistBoxSearchIcon, EarthIcon, FlagIcon, GamepadIcon, GraduateCapIcon, MacbookIcon, PeopleCircleIcon, PeopleTogetherIcon, SpaceIcon, TagIcon, WalletIcon, } from '@marvelapp/ballpark-icons';
export const getIconForFilter = (id) => {
    switch (id) {
        case 'GROUP_BELIEFS_PROLIFIC':
            return React.createElement(SpaceIcon, null);
        case 'GROUP_HEALTH_PROLIFIC':
            return React.createElement(AppleIcon, null);
        case 'GROUP_FAMILY__RELATIONSHIPS_PROLIFIC':
            return React.createElement(PeopleTogetherIcon, null);
        case 'GROUP_LANGUAGES_PROLIFIC':
            return React.createElement(FlagIcon, null);
        case 'GROUP_GEOGRAPHIC_PROLIFIC':
            return React.createElement(EarthIcon, null);
        case 'FILTER_EMPLOYMENT':
        case 'FILTER_COMPANY_SIZE':
        case 'FILTER_INDUSTRY':
        case 'FILTER_JOB_TITLES':
        case 'GROUP_WORK_PROLIFIC':
            return React.createElement(BuildingsIcon, null);
        case 'FILTER_EDUCATION_LEVEL':
        case 'FILTER_SKILLS':
        case 'GROUP_EDUCATION_PROLIFIC':
            return React.createElement(GraduateCapIcon, null);
        case 'FILTER_ETHNICITY':
        case 'FILTER_GENDER':
        case 'GROUP_GENERAL':
            return React.createElement(PeopleCircleIcon, null);
        case 'FILTER_HOUSEHOLD_INCOME':
        case 'GROUP_FINANCE_PROLIFIC':
            return React.createElement(WalletIcon, null);
        case 'GROUP_LIFESTYLE_AND_INTERESTS_PROLIFIC':
            return React.createElement(GamepadIcon, null);
        case 'GROUP_TECHNOLOGY_AND_ONLINE_BEHAVIOUR_PROLIFIC':
            return React.createElement(MacbookIcon, null);
        case 'GROUP_SHOPPING_AND_CONSUMER_HABITS_PROLIFIC':
            return React.createElement(BagShoppingIcon, null);
        case 'GROUP_OTHER_PROLIFIC':
            return React.createElement(ChecklistBoxSearchIcon, null);
        case 'FILTER_TOPICS':
        default:
            return React.createElement(TagIcon, null);
    }
};
