import React from 'react';
import { Link } from '../Link';
import { CategorySlug } from '../types';
export const paperPrototypeTest = {
    slug: 'paper-prototype-test',
    title: 'Paper Prototype Test Template | Ballpark',
    metaDescription: 'See how mock-ups of your design perform with your users with our paper prototype test template. Test prototypes and validate ideas for apps and websites.',
    heading: 'Paper prototype test template',
    subheading: 'Validate your sketches before jumping into visuals',
    categories: [CategorySlug.PrototypeTesting, CategorySlug.DesignAndProduct],
    body: React.createElement(PaperPrototypeTest, null),
    templateUUID: 'ut_413204cb-7fb2-4491-a7e1-6d2f601ba607',
    recordLink: 'https://app.ballparkhq.com/record/413204cb-7fb2-4491-a7e1-6d2f601ba607',
    accordionTitle: 'Paper prototyping testing template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'How do I create a paper prototype to use with Ballpark?',
            answerParagraphs: [
                'You have a few options to get started. First, you can take photos of your sketches on paper using your phone and upload them to Figma to turn them into a prototype.',
                React.createElement(AccordionItem1, null),
            ],
        },
        {
            id: 2,
            question: 'Do you recommend any templates I can print out for my sketches?',
            answerParagraphs: [React.createElement(AccordionItem2, null)],
        },
        {
            id: 3,
            question: 'How does this template work in a design thinking session?',
            answerParagraphs: [
                'There are five stages in a design thinking process - empathise, define, ideate, prototype and test. Naturally, the test stage is where this template will come in handy.',
                'The test stage helps you answer the question, "how well does your prototype solve the problem you initially defined?"',
                "The goal is to observe your user's reactions and behaviour with the prototype without giving instructions, then gather feedback that can be used to iterate and improve the experience.",
                'By using this template, you should have a list of suggestions and next steps to improve your solution’s overall experience.',
            ],
        },
    ],
};
function PaperPrototypeTest() {
    return (React.createElement("div", null,
        React.createElement("p", null, "Paper prototypes are invaluable when you\u2019re trying to explore your design ideas, before going on to develop more complex mock-ups. Use them early in your process to help you understand what users need and what your service needs to offer."),
        React.createElement("p", null, "Using this Ballpark template in conjunction with your low-fidelity prototype means it\u2019s the perfect research method for early-stage concepts and non-designers as all you need is pen and paper!"),
        React.createElement("h3", null, "4 great ways to use the paper prototype test template"),
        React.createElement("ul", null,
            React.createElement("li", null, "Validate concepts at a super early stage and move faster"),
            React.createElement("li", null, "Perfect for non-designers testing out hand draw wireframes, designs and ideas"),
            React.createElement("li", null, "It doesn\u2019t have to be an app or website - test anything that you can draw including concept sketches of physical items, buildings and logos"),
            React.createElement("li", null, "At the final stage of a design thinking process when you\u2019re ready to test"))));
}
function AccordionItem1() {
    return (React.createElement("div", null,
        React.createElement("p", null,
            "Alternatively you can use a dedicated app to create and upload your prototypes such as",
            ' ',
            React.createElement(Link, { href: "https://apps.apple.com/us/app/marvel-design-and-prototype/id765801658", target: "_blank" }, "Marvel for iOS"),
            ' ',
            "and",
            ' ',
            React.createElement(Link, { href: "https://play.google.com/store/apps/details?id=com.marvelapp", target: "_blank" }, "Android"),
            ". Simply create your paper prototype and put the share link into your Ballpark step.")));
}
function AccordionItem2() {
    return (React.createElement("p", null,
        "Yes! Sneakpeekit has several templates that you can download and print out, including ones for mobile and desktop devices. Head",
        ' ',
        React.createElement(Link, { href: "https://sneakpeekit.com/", target: "_blank" }, "here")));
}
