export var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "Active";
    UserStatus["Pending"] = "Pending";
})(UserStatus || (UserStatus = {}));
export var UserRole;
(function (UserRole) {
    UserRole["Guest"] = "Guest";
    UserRole["Member"] = "Member";
})(UserRole || (UserRole = {}));
export var UserPermissions;
(function (UserPermissions) {
    UserPermissions["Owner"] = "Owner";
    UserPermissions["Admin"] = "Admin";
    UserPermissions["Editor"] = "Editor";
    UserPermissions["Viewer"] = "Viewer";
})(UserPermissions || (UserPermissions = {}));
