import { defaultColumnSizing, flexRender, } from '@tanstack/react-table';
import React, { useCallback, useLayoutEffect, useRef, useState, } from 'react';
import { ArrowBottomIcon, ArrowTopIcon, SortIcon, } from '@marvelapp/ballpark-icons';
import { Loader } from '@marvelapp/ui';
import { BtwLink } from '../BtwLink';
import { BtwSkeleton } from '../BtwSkeleton';
import { BtwText } from '../BtwText';
import { Stack } from '../Stack';
import { cn } from '../utils';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from './BaseTable';
export function DataTable({ table, getRows = () => table.getRowModel().rows, paddingTop = 0, paddingBottom = 0, withStickyHeader, loadingStatus = 'none', isRowHighlighted, adjustColumnWidths = false, renderRowAttrs, renderNoItems = () => null, rowTestIdPrefix = 'response', }) {
    const rows = getRows();
    const hasTableLoaded = loadingStatus !== 'loading';
    const { thRefs, thWidths } = useOptimalColumnWidths({
        adjustColumnWidths,
        hasTableLoaded,
    });
    const renderBody = useCallback(() => {
        if (loadingStatus === 'none' && rows.length === 0) {
            return renderNoItems();
        }
        return (React.createElement(React.Fragment, null,
            loadingStatus === 'reloading' && (React.createElement("tr", null,
                React.createElement("td", { colSpan: rows.length },
                    React.createElement(Stack, { align: "center", justify: "center", 
                        // Doing some funky calcs here, but it's basically doing 100vh/vw excluding
                        // the paddings, borders and other elements on the page.
                        className: "absolute left-0 top-0 z-[300] h-[calc(100vh_-_128px)] w-[calc(100vw_-_34px)]" },
                        React.createElement(Stack, { align: "center", justify: "center", width: "full", className: "fixed" },
                            React.createElement(Loader, null)))))),
            rows.map((row) => (React.createElement(TableRow, Object.assign({ className: cn('h-0', loadingStatus === 'reloading' ? 'opacity-25' : null), "data-testid": `${rowTestIdPrefix}-${row.id}`, "data-highlighted": isRowHighlighted ? isRowHighlighted(row) : false, key: row.id, "data-state": row.getIsSelected() && 'selected' }, (renderRowAttrs ? renderRowAttrs(row) : {})), row.getVisibleCells().map((cell) => (React.createElement(StyledTableCell, { key: cell.id, "data-testid": `cell-${cell.id}` }, flexRender(cell.column.columnDef.cell, cell.getContext())))))))));
    }, [
        loadingStatus,
        renderNoItems,
        renderRowAttrs,
        rows,
        isRowHighlighted,
        rowTestIdPrefix,
    ]);
    if (loadingStatus === 'loading') {
        return (React.createElement("div", { className: "flex h-full items-center justify-center" },
            React.createElement(Loader, null)));
    }
    return (React.createElement(Table, { className: "relative h-fit w-max min-w-full" },
        React.createElement(TableHeader, null, table.getHeaderGroups().map((headerGroup) => (React.createElement(TableRow, { key: headerGroup.id, className: "relative z-30 h-full" }, headerGroup.headers.map((header, index) => {
            return (React.createElement(TableHead, { key: header.id, ref: (el) => {
                    if (!el)
                        return;
                    thRefs.current[index] = el;
                }, colSpan: header.colSpan, className: cn(
                // Default styles for all header cells
                'border-l', 'max-w-sm', 'bg-white', 
                // Hide left and add a right border to first child
                'first:border-l-0', 'first:border-r', 
                // Fix the first child to the left, if there's a sticky first column
                '[&:has([role=checkbox])]:left-0', '[&:has([role=checkbox])]:z-[30]', '[&:has([role=checkbox])]:relative', 
                // Hide left border from 2nd child, cause we have a right border on the 1st
                '[&:nth-child(2)]:border-l-0', 'hover:z-30'), style: Object.assign({ 
                    // Getting column widths from the config in:
                    // src/applications/askhub-ui/src/pages/NewResponses/columnUtils.tsx
                    // while ignoring the library default min width (20), so we can
                    // fix dynamic widths to all other columns
                    //
                    width: header.getSize() !== defaultColumnSizing.minSize
                        ? header.getSize()
                        : thWidths[index], boxSizing: 'border-box' }, (withStickyHeader
                    ? {
                        position: 'sticky',
                        top: 0,
                    }
                    : {})) },
                React.createElement(BtwText, { variant: "primary", weight: "medium", leading: "tight", className: "flex h-full w-full flex-col justify-end", "data-testid": "column-header" },
                    React.createElement(SortButton, { header: header }, header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())))));
        }))))),
        React.createElement(TableBody, null,
            React.createElement("tr", { className: "h-0" },
                React.createElement("td", { style: { height: paddingTop || 0 } })),
            renderBody(),
            loadingStatus === 'fetchMore' && (React.createElement(React.Fragment, null,
                React.createElement(LoadingRow, { table: table }),
                React.createElement(LoadingRow, { table: table }))),
            React.createElement("tr", { className: "h-0" },
                React.createElement("td", { style: { height: paddingBottom || 0 } })))));
}
function StyledTableCell({ children, className, 'data-testid': testId, }) {
    return (React.createElement(TableCell, { className: cn(
        // Default left border for all cells
        'border-l', 'max-w-sm', 
        // Fixed column-specific styling
        'first:border-l-0', 'first:border-r', '[&:has([role=checkbox])]:sticky', '[&:has([role=checkbox])]:left-0', '[&:has([role=checkbox])]:z-20', '[&:has([role=checkbox])]:bg-white', '[&:nth-child(2)]:border-l-0', className), "data-testid": testId }, children));
}
function LoadingRow({ table }) {
    return (React.createElement(TableRow, null, table.getVisibleFlatColumns().map((column) => (React.createElement(StyledTableCell, { key: column.id, className: "h-12" },
        React.createElement(BtwSkeleton, { className: "h-2 w-full" }))))));
}
function SortButton({ header, children }) {
    const { column } = header;
    if (!column.getCanSort())
        return React.createElement(React.Fragment, null, children);
    return (React.createElement(BtwLink, { onClick: column.getToggleSortingHandler(), underline: false, variant: "current", className: "select-none" },
        React.createElement(BtwText, { weight: "medium", variant: "primary", leading: "tight" },
            React.createElement(Stack, { direction: "row", align: "center", gap: "1" },
                children,
                " ",
                getSortingIcon(column)))));
}
function getSortingIcon(column) {
    const isSorted = column.getIsSorted();
    switch (isSorted) {
        case 'asc':
            return (React.createElement(ArrowTopIcon, { "data-testid": "asc-icon", className: "h-4 w-4 text-gray-500" }));
        case 'desc':
            return (React.createElement(ArrowBottomIcon, { "data-testid": "desc-icon", className: "h-4 w-4 text-gray-500" }));
        default:
            return React.createElement(SortIcon, { className: "h-4 w-4 text-gray-300" });
    }
}
// The width of the table cell is adjusted based on its initial rendering.
// This approach ensures that the cell width remains static and does not
// dynamically adjust, preventing any layout shifts when rows are added or removed.
function useOptimalColumnWidths({ adjustColumnWidths, hasTableLoaded, }) {
    const thRefs = useRef([]);
    const [thWidths, setThWidths] = useState([]);
    useLayoutEffect(() => {
        if (!adjustColumnWidths || !hasTableLoaded || thWidths.length > 0)
            return;
        const newWidths = thRefs.current.map((th) => th.getBoundingClientRect().width);
        setThWidths(newWidths);
    }, [adjustColumnWidths, hasTableLoaded, thWidths]);
    return { thRefs, thWidths };
}
