import React from 'react';
import { tv } from 'tailwind-variants';
const separator = tv({
    base: ['bg-gray-200', 'flex-0'],
    variants: {
        direction: {
            horizontal: ['h-px', 'w-full'],
            vertical: ['h-full', 'w-px'],
        },
    },
    defaultVariants: {
        direction: 'horizontal',
    },
}, {
    responsiveVariants: true,
});
function Separator(props) {
    const { className, direction } = props;
    return React.createElement("div", { className: separator({ className, direction }) });
}
export { Separator };
