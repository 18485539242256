import React from 'react';
import { BigBuildingIcon, BuildingsIcon, CalendarTimeIcon, GrowthIcon, LockIcon, } from '@marvelapp/ballpark-icons';
import { BtwButton } from '../BtwButton';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { Progress } from '../Progress';
import { AppSidebar, SidebarNavItem, SidebarPanel } from '../Sidebar';
import { Stack } from '../Stack';
import { cn } from '../utils';
import { SettingsHeader } from './SettingsHeader';
var ActivePlan;
(function (ActivePlan) {
    ActivePlan["trial"] = "Trial";
    ActivePlan["starter"] = "Starter";
    ActivePlan["business"] = "Business";
    ActivePlan["enterprise"] = "Enterprise";
})(ActivePlan || (ActivePlan = {}));
function PlanUsageSettingsPage(props) {
    const { subscriptionFee, trialStartDate, trialEndDate, nextPaymentDate, activeProjects, totalProjects, creditsUsed, creditsBalance, totalCredits, annualCredits, activePlan, } = props;
    const currentDate = new Date();
    const isTrialExpired = currentDate >= trialEndDate;
    return (React.createElement(Stack, { width: "full", className: "h-full", direction: "row" },
        React.createElement(AppSidebar, { initialMenuItem: SidebarNavItem.Settings_PlanUsage, initialActivePanel: SidebarPanel.Settings }),
        React.createElement(Stack, { className: "h-full overflow-y-scroll ", width: "full" },
            React.createElement(SettingsHeader.Container, null,
                React.createElement(SettingsHeader.Title, null, "Plan & Usage"),
                React.createElement(SettingsHeader.Actions, null,
                    React.createElement(BtwButton, { size: "sm" }, "Change billing details"))),
            React.createElement(Stack, { width: "full", gap: "8", className: "mx-auto h-full max-w-6xl flex-1 overflow-y-scroll" },
                React.createElement(Stack, { width: "full", className: "h-full p-8", gap: "8" },
                    React.createElement(ActivePlanCard, { activePlan: activePlan, subscriptionFee: subscriptionFee, annualCredits: 100, nextPaymentDate: nextPaymentDate, trialStartDate: trialStartDate, trialEndDate: trialEndDate, currentDate: currentDate, isTrialExpired: isTrialExpired }),
                    React.createElement(Stack, { direction: { initial: 'col', md: 'row' }, gap: "8", align: "stretch", width: "full" },
                        React.createElement(ProjectsCard, { activeProjects: activeProjects, totalProjects: totalProjects, activePlan: activePlan, isTrialExpired: isTrialExpired }),
                        React.createElement(CreditsCard, { creditsBalance: creditsBalance, totalCredits: totalCredits, annualCredits: annualCredits, activePlan: activePlan, isTrialExpired: isTrialExpired, creditsUsed: creditsUsed })))))));
}
function ActivePlanCard(props) {
    const { activePlan, subscriptionFee, trialStartDate, trialEndDate, annualCredits, nextPaymentDate, isTrialExpired, currentDate, } = props;
    return (React.createElement(Stack, { width: "full", className: "rounded-xl bg-white shadow-sm ring-1 ring-gray-600/10" },
        React.createElement(Stack, { width: "full", direction: { initial: 'col', sm: 'row' }, gap: { initial: '6', md: '8' }, className: "relative px-8 py-6", align: "start" },
            React.createElement(Stack, { className: cn('absolute', 'top-6', 'right-8', 'sm:relative', 'sm:top-0', 'sm:right-0') },
                React.createElement(ActivePlanIcon, { activePlan: activePlan, isTrialExpired: isTrialExpired })),
            React.createElement(Stack, { direction: { initial: 'col', sm: 'row' }, width: "full", gap: "8", wrap: "wrap", className: "gap-y-2 *:flex-1 lg:*:flex-initial lg:last:*:flex-1" },
                React.createElement(Stack, { className: 
                    // Making sure the first column doesn't grow in size when the plan is Enterprise
                    // or Trial that is expired. Helps with layout consistency. Trust me!
                    activePlan === ActivePlan.enterprise ||
                        (activePlan === ActivePlan.trial && isTrialExpired)
                        ? '!flex-initial'
                        : '' },
                    React.createElement(BtwText, { size: "13", weight: "medium" }, "Plan"),
                    React.createElement(BtwHeading, { size: "lg", weight: "medium" }, activePlan)),
                activePlan === ActivePlan.trial && !isTrialExpired && (React.createElement(React.Fragment, null,
                    React.createElement(Stack, null,
                        React.createElement(BtwText, { size: "13", weight: "medium" }, "Amount"),
                        React.createElement(BtwHeading, { size: "lg", weight: "medium" }, "Free")),
                    React.createElement(Stack, null,
                        React.createElement(BtwText, { size: "13", weight: "medium" }, "Trial ends"),
                        React.createElement(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap" }, trialEndDate && formatDate(trialEndDate))))),
                activePlan === ActivePlan.trial && isTrialExpired && (React.createElement(Stack, { className: "flex-auto md:w-min" },
                    React.createElement(BtwText, { size: "13", weight: "medium" }, "Details"),
                    React.createElement(BtwText, { size: "sm", variant: "primary", className: "pt-1" }, "Your trial period has ended. Upgrade to a paid plan to continue using the platform."))),
                (activePlan === ActivePlan.starter ||
                    activePlan === ActivePlan.business) && (React.createElement(React.Fragment, null,
                    React.createElement(Stack, null,
                        React.createElement(BtwText, { size: "13", weight: "medium" }, "Amount"),
                        React.createElement(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap" },
                            "$",
                            subscriptionFee,
                            " / month")),
                    React.createElement(Stack, null,
                        React.createElement(BtwText, { size: "13", weight: "medium" }, "Credits"),
                        React.createElement(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap" },
                            annualCredits,
                            " / year")),
                    React.createElement(Stack, null,
                        React.createElement(BtwText, { size: "13", weight: "medium" }, "Next payment"),
                        React.createElement(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap" }, nextPaymentDate &&
                            nextPaymentDate.toLocaleDateString('en-GB', {
                                dateStyle: 'medium',
                            }))))),
                activePlan === ActivePlan.enterprise && (React.createElement(Stack, { className: "flex-auto md:w-min" },
                    React.createElement(BtwText, { size: "13", weight: "medium" }, "Details"),
                    React.createElement(BtwText, { size: "sm", variant: "primary", className: "pt-1" },
                        "This plan is billed manually. Please contact",
                        ' ',
                        React.createElement("a", { href: "mailto:experience@ballparkhq.com", className: "!underline" }, "experience@ballparkhq.com"),
                        ' ',
                        "to discuss your plan.")))),
            activePlan === ActivePlan.trial && (React.createElement(Stack, { className: "shrink-0 pt-1.5" },
                React.createElement(BtwButton, { size: "intermediate", variant: "primary" }, "Purchase plan"))),
            (activePlan === ActivePlan.starter ||
                activePlan === ActivePlan.business) && (React.createElement(Stack, { className: "shrink-0 pt-1.5" },
                React.createElement(BtwButton, { size: "intermediate", variant: "primary" }, "Upgrade")))),
        activePlan === ActivePlan.trial && !isTrialExpired && (React.createElement(TrialProgressBar, { trialStartDate: trialStartDate, trialEndDate: trialEndDate, currentDate: currentDate }))));
}
function ActivePlanIcon(props) {
    const { activePlan, isTrialExpired } = props;
    const activePlanIconBaseClasses = [
        // Base styles
        'rounded-full',
        'p-3',
    ];
    switch (activePlan) {
        case ActivePlan.trial:
            return isTrialExpired ? (React.createElement(Stack, { className: cn(activePlanIconBaseClasses, 'bg-amber-400') },
                React.createElement(LockIcon, { className: "size-5 text-amber-50" }))) : (React.createElement(Stack, { className: cn(activePlanIconBaseClasses, 'bg-amber-200') },
                React.createElement(CalendarTimeIcon, { className: "size-5 text-amber-600" })));
        case ActivePlan.starter:
            return (React.createElement(Stack, { className: cn(activePlanIconBaseClasses, 'bg-lime-200') },
                React.createElement(GrowthIcon, { className: "size-5 text-lime-600" })));
        case ActivePlan.business:
            return (React.createElement(Stack, { className: cn(activePlanIconBaseClasses, 'bg-indigo-200') },
                React.createElement(BuildingsIcon, { className: "size-5 text-indigo-600" })));
        case ActivePlan.enterprise:
            return (React.createElement(Stack, { className: cn(activePlanIconBaseClasses, 'bg-purple-200') },
                React.createElement(BigBuildingIcon, { className: "size-5 text-purple-600" })));
        default:
            return null;
    }
}
function TrialProgressBar(props) {
    const { trialStartDate, trialEndDate, currentDate } = props;
    let trialLength;
    let trialDaysUsed;
    let trialDaysLeft;
    let percentage = 0;
    // Get the lenght of the trial period in days
    if (trialEndDate && trialStartDate) {
        trialLength = calculateDaysDifference(trialEndDate, trialStartDate);
    }
    // Get the number of trial days used
    if (trialStartDate) {
        trialDaysUsed = calculateDaysDifference(currentDate, trialStartDate);
    }
    // Get the number of days left in the trial
    if (trialEndDate) {
        trialDaysLeft = calculateDaysDifference(trialEndDate, currentDate);
    }
    if (trialDaysUsed && trialLength) {
        percentage = (trialDaysUsed / trialLength) * 100;
    }
    return (React.createElement(Stack, { width: "full", className: "px-8 py-3", direction: "row", gap: "6", align: "center" },
        React.createElement(Stack, { width: "full" },
            React.createElement(Progress.Animated, { value: percentage })),
        React.createElement(Stack, { className: "shrink-0" },
            React.createElement(BtwText, { size: "13", weight: "medium" },
                trialDaysLeft,
                " days left"))));
}
function ProjectsCard(props) {
    const { activeProjects, totalProjects, activePlan, isTrialExpired } = props;
    // Setting the corrent heading
    let heading;
    if (activePlan === ActivePlan.trial) {
        if (isTrialExpired) {
            heading = 'Projects locked';
        }
        else {
            heading = 'Unlimited';
        }
    }
    else {
        heading = `${activeProjects} active projects`;
    }
    // Setting the correct description
    let description;
    if (activePlan === ActivePlan.trial && !isTrialExpired) {
        description =
            'You can create unlimited projects as part of your trial. Explore our plans to find our more.';
    }
    else if (activePlan === ActivePlan.trial && isTrialExpired) {
        description = `You created ${activeProjects} projects during your trial \u2014 sounds productive! To create new projects and access previous ones, you need to upgrade to a paid plan.`;
    }
    else if (activePlan !== ActivePlan.trial &&
        activeProjects >= totalProjects) {
        description =
            'You have reached the maximum concurrent active projects under your current plan. You can either archive some projects or consider upgrading.';
    }
    else {
        description = ` You can have a total of ${totalProjects} active projects with your current plan \u2014 this means you can launch ${totalProjects - activeProjects} more active studies! 👏`;
    }
    const actions = activePlan !== ActivePlan.enterprise && (React.createElement(React.Fragment, null, activePlan === ActivePlan.trial ? (React.createElement(BtwButton, { size: "intermediate", variant: "primary", className: "w-full lg:w-auto" }, "Purchase plan")) : (React.createElement(BtwButton, { size: "intermediate", variant: "primary", className: "w-full lg:w-auto" }, "Upgrade"))));
    return (React.createElement(React.Fragment, null,
        React.createElement(AllowanceCard, { subHeading: "Projects", heading: heading, description: description, allowanceValue: activeProjects, allowanceMax: totalProjects, showProgressBar: activePlan !== ActivePlan.trial ||
                (activePlan === ActivePlan.trial && !isTrialExpired), actions: actions, isTrialExpired: isTrialExpired && activePlan === ActivePlan.trial })));
}
function CreditsCard(props) {
    const { creditsUsed, creditsBalance, totalCredits, annualCredits, activePlan, isTrialExpired, } = props;
    // Setting the description based on plan
    let heading;
    if (creditsBalance <= 0) {
        heading = "You've used up all your credits";
    }
    else {
        heading = `${creditsBalance} credits left`;
    }
    let description;
    if (activePlan === ActivePlan.trial && !isTrialExpired) {
        description =
            'You have 10 recruitment minutes as part of your trial allowance. You can purchase more as needed.';
    }
    else if (activePlan === ActivePlan.trial && isTrialExpired) {
        description =
            'To continue using your recruitment minutes, you need to upgrade to a paid plan.';
    }
    else if (activePlan !== ActivePlan.trial && creditsBalance <= 0) {
        description =
            "You've used up all of your recruitment credits. You need to top up your credits balance if you want to recruit more participants for your studies.";
    }
    else {
        description = `You have ${annualCredits} recruitment minutes per year with your current plan. You can always buy more credits when you need them.`;
    }
    // Actions
    const actions = (
    // Doing some funky dance here to make sure the buttons look nice across the various viewports.
    React.createElement(Stack, { direction: { initial: 'col', sm: 'row', md: 'col', lg: 'row' }, gap: "4", width: "full", className: "*:w-full *:lg:w-auto" },
        React.createElement(BtwButton, { size: "intermediate" }, "Buy credits"),
        React.createElement(BtwButton, { size: "intermediate", variant: "ghost" }, "View usage")));
    return (React.createElement(AllowanceCard, { subHeading: "Recruitment credits", heading: heading, description: description, showProgressBar: true, allowanceValue: creditsUsed, allowanceMax: totalCredits, actions: actions, isTrialExpired: isTrialExpired && activePlan === ActivePlan.trial }));
}
function AllowanceCard(props) {
    const { subHeading, heading, description, showProgressBar, allowanceValue, allowanceMax, actions, isTrialExpired, } = props;
    return (React.createElement(Stack, { className: "relative overflow-hidden rounded-xl border border-gray-600/10 bg-gray-50 bg-[radial-gradient(#D1D5DB_1px,transparent_1px)] bg-right-top shadow-sm [background-size:16px_16px]", width: "full" },
        React.createElement(Stack, { gap: "6", className: "flex-1 bg-gradient-to-tr from-gray-50 to-gray-50/50 px-8 py-6", width: "full" },
            React.createElement(Stack, { gap: "6", width: "full", className: "flex-1" },
                React.createElement(Stack, { gap: "3", width: "full" },
                    React.createElement(Stack, { direction: { initial: 'colReverse', md: 'row' }, gap: "4", width: "full", align: { initial: 'start', md: 'center' } },
                        React.createElement(Stack, { gap: { initial: '2', md: '3' }, width: "full" },
                            React.createElement(BtwText, { size: "13", weight: "medium" }, subHeading),
                            React.createElement(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap" }, heading)),
                        isTrialExpired && (React.createElement("div", { className: "" },
                            React.createElement(ActivePlanIcon, { isTrialExpired: true, activePlan: ActivePlan.trial })))),
                    React.createElement(BtwText, { size: "13" }, description)),
                showProgressBar && (React.createElement(AllowanceProgressBar, { value: allowanceValue, max: allowanceMax }))),
            actions && actions)));
}
function AllowanceProgressBar(props) {
    const { value, max } = props;
    const percentage = (value / max) * 100;
    return (React.createElement(Stack, { direction: "row", gap: "8", align: "center", width: "full" },
        React.createElement(Stack, { width: "full" },
            React.createElement(Progress.Animated, { value: percentage })),
        React.createElement(Stack, { className: "shrink-0" },
            React.createElement(BtwText, { size: "sm", weight: "medium" },
                value,
                "/",
                max,
                ' ',
                React.createElement("span", { className: "sm:inline md:hidden lg:inline" }, "used")))));
}
function formatDate(date) {
    return new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' }).format(date);
}
function calculateDaysDifference(start, end) {
    // I have to create new Date objects again because Storybook's knobs will create
    // a Unix timestamp and the getTime() will poop out.
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeDifference = startDate.getTime() - endDate.getTime();
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
}
export { PlanUsageSettingsPage, ActivePlan };
