import React from 'react';
import styled from '@marvelapp/styled';
import { CategorySlug } from '../types';
const BulletList = styled.li `
  list-style-type: disc;
`;
export const npsSurvey = {
    slug: 'nps-survey',
    title: 'NPS Survey Template | Ballpark',
    metaDescription: 'NPS is used to measure customer satisfaction and the health of your users. Use our NPS survey template to track customer sentiment over time.',
    heading: 'NPS survey (Net Promoter Score®) template',
    subheading: 'Learn about your brand’s reputation (or another brand’s reputation before comparing it to yours)',
    categories: [
        CategorySlug.Popular,
        CategorySlug.Survey,
        CategorySlug.CustomerSuccess,
    ],
    body: React.createElement(NPSSurveyBody, null),
    templateUUID: 'ut_26e554e5-9f5c-4077-9fa0-bf9718ddeeb9',
    recordLink: 'https://app.ballparkhq.com/record/26e554e5-9f5c-4077-9fa0-bf9718ddeeb9',
    accordionTitle: 'NPS survey template FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'When should you send NPS surveys?',
            answerParagraphs: [
                'You should send NPS surveys to customers after they’ve acquired some solid experience with the product and brand. A common mistake that organizations make is asking customers to take NPS surveys immediately after checking out, onboarding, or whatever their initial experience is. Usually, this experience barely scratches the surface of the core product, so any feedback given at this point could later prove to be inaccurate or out of date.',
                'In short, let customers have a full (or full-ish) experience before sending them an NPS survey.',
            ],
        },
        {
            id: 2,
            question: 'How often should you run NPS surveys?',
            answerParagraphs: [
                'Generally speaking, the industry standard is to survey customers every 6 months at least. Products that change more frequently should carry out NPS surveying as often as monthly.',
            ],
        },
        {
            id: 3,
            question: 'What do the different scores mean in an NPS survey?',
            answerParagraphs: [
                'After asking a customer how likely they are to recommend your brand to others on a scale of 0-10, their answer will fall into one of three categories:',
                React.createElement(React.Fragment, null,
                    React.createElement("ul", null,
                        React.createElement(BulletList, null, "Promoters: those that score between 9 and 10 are considered to be extremely satisfied and are likely to recommend your brand to others"),
                        React.createElement(BulletList, null, "Passives: those that score between 7 and 8 are considered to be satisfied, but perhaps not enough to recommend your brand to others"),
                        React.createElement(BulletList, null, "Detractors: those that score between 0 and 6 are considered to be unsatisfied and may even discourage others from engaging with your brand"))),
                'Subtracting the percentage of ‘detractors’ from the percentage of ‘promoters’, you’ll end up with a conclusive Net Promoter Score ranging from -100 to +100. The number of ‘passives’ is inconsequential since they’re not likely to speak positively or negatively about the brand to other people.',
            ],
        },
        {
            id: 4,
            question: 'Which score should I aim for?',
            answerParagraphs: [
                'Putting aside the fact that you’ll naturally want to aim for the highest score possible (which is +100), the Net Promoter Score that truly matters is that of your competitors. Your score needs to be higher than theirs in order to be able to consider that your customers are the most satisfied and your brand has the higher potential for growth based on word-of-mouth.',
            ],
        },
    ],
};
function NPSSurveyBody() {
    return (React.createElement("div", null,
        React.createElement("h3", null, "What is an NPS survey?"),
        React.createElement("p", null, "Firstly, NPS stands for Net Promoter Score, and this score represents your customer\u2019s collective sentiment towards your brand based on their overall experience with it."),
        React.createElement("p", null, "An NPS survey is the survey that\u2019s used to discover the score, which is calculated using the answers returned in the survey."),
        React.createElement("h3", null, "5 great ways to use our NPS survey template"),
        React.createElement("p", null, "One of the great things about using NPS surveys is there are many ways to leverage the results to create better experiences for your customers."),
        React.createElement("ul", null,
            React.createElement("li", null, "Measure and understand customer sentiment over time"),
            React.createElement("li", null, "Find your customer champions "),
            React.createElement("li", null, "Get direction for change and improvement"),
            React.createElement("li", null, "Reduce future churn by discovering unhappy customers early"),
            React.createElement("li", null, "Benchmark your experience against competitors")),
        React.createElement("h3", null, " Why are NPS surveys used?"),
        React.createElement("p", null, "NPS surveys are used to find out how likely your customers are to recommend your brand to others, which is considered to be a good indicator of overall customer sentiment; so simply put, NPS surveys can quickly and easily reveal whether or not your brand is on the right track."),
        React.createElement("p", null, "Also, NPS surveys can be repeated over time to track improvement and repeated with other brands to see how their reputation compares with yours."),
        React.createElement("p", null, "As an added bonus, NPS surveys are incredibly simple, which makes it super easy to collect the necessary data, calculate the score, and rinse and repeat every now and then."),
        React.createElement("p", null, "With our NPS survey template, additional survey questions are included to help you learn about the reasoning behind the score and what needs to change, improve, or stay the same. On an individual level, the additional survey questions can help you to reduce churn by revealing your unhappy customers and reward loyalty by revealing your biggest champions."),
        React.createElement("p", null, "Remember, NPS surveys without follow-up questions mean it\u2019s just a number. Our NPS template adds several questions to give you more context to the score given."),
        React.createElement("h3", null, "Who uses NPS surveys?"),
        React.createElement("p", null, "Customer journeys are often made up of multiple experiences (e.g. user experiences, customer support experiences, delivery experiences, etc.). Ideally, NPS surveys should be spearheaded by those that oversee the overall customer experience, who can then share the insights with the rest of the organization."),
        React.createElement("p", null, "However, individual teams (e.g. design teams) can also use NPS surveys to the same effect by adjusting the questions to begin with \u201CBased on your experience of \u2026\u201D when the need to run smaller-scale NPS surveys arise."),
        React.createElement("h3", null, "What types of data does the NPS survey yield?"),
        React.createElement("p", null, "It depends on the scope of the survey. NPS surveys without follow-up questions will only yield the Net Promoter Score, a quantitative value that depicts your brand\u2019s reputation. Our NPS survey template includes additional questions for more qualitative context (i.e. why the Net Promoter Score is what it is and what can be done to improve or maintain it)."),
        React.createElement("h3", null, "What questions are in the NPS survey template?"),
        React.createElement("p", null, "The main question relates to, of course, the score itself, where respondents are asked to rate how likely they are to recommend your brand to others on a scale of 0 (meaning \u201Cnot at all likely\u201D) to 10 (meaning \u201Cextremely likely\u201D). Collectively, the answers to this question result in your Net Promoter Score."),
        React.createElement("p", null, "After that, respondents are asked why they chose their rating and what would have to change for it to improve, providing you with some useful qualitative insight."),
        React.createElement("p", null, "The rest of the survey asks respondents about their role in their organization, how that role requires them to use the product, and their organization\u2019s size. Being able to segment the results based on the answers to these questions is important because doing so can help you to create tailored experiences rather than a singular experience that fails to cater for everyone. These additional survey questions can paint a clearer picture of what the different user roles want."),
        React.createElement("p", null, "Also, the survey can be customized with additional questions such as \u201CCan we get in touch with you for follow-up questioning about your feedback?\u201D. It\u2019s never safe to make assumptions when you\u2019re unsure about an answer, so being able to reach out and clarify can be very useful.")));
}
