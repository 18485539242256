import React from 'react';
import { CategorySlug } from '../types';
export const firstImpressionsTest = {
    slug: 'first-impressions-test',
    title: 'First Impressions Test Template | Ballpark',
    metaDescription: "First impressions of a design, brand or ad can determine its effectiveness. Use our first impression test template to gauge a user's initial understanding.",
    heading: 'First impression test template',
    subheading: "Gauge users' initial thoughts and feelings",
    categories: [CategorySlug.BrandAndCopyTesting, CategorySlug.Marketing],
    body: React.createElement(FirstImpressionsTestBody, null),
    templateUUID: 'ut_d05eac47-045a-4899-b3ef-2da06cdbe669',
    recordLink: 'https://app.ballparkhq.com/record/d05eac47-045a-4899-b3ef-2da06cdbe669',
    accordionTitle: 'First impression test FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'Why are the first 5 seconds so important?',
            answerParagraphs: [
                "First impression tests are very important because modern users are impatient and will exit or 'go back' if they can't find what they’re looking for or trust the business/brand/app/website immediately. Worst-case scenario is that users aren't even sure if they've encountered what they're looking for due to clutter or confusing visual/written communication.",
                'Sometimes, even a clumsily designed or clumsily worded logo can mislead users about what a business actually offers.',
            ],
        },
        {
            id: 2,
            question: 'Which question formats are useful?',
            answerParagraphs: [
                'The beauty of first impression tests lies in the diversity of question formats. Open-ended questions are the most common but you can also ask closed-ended questions (consisting of rating scales, for example).',
            ],
        },
    ],
};
function FirstImpressionsTestBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "A first impression test involves showing users a design for 5 seconds with no instruction whatsoever \u2014 users can look at whatever catches their attention."),
        React.createElement("p", null, "After this, users are asked a series of open and closed-ended questions. These questions can ask about trustworthiness, brand impression, visual hierarchy, or gain an understanding of what (they believe) the business/app/website actually offers."),
        React.createElement("h3", null, "4 great ways to use our first impression test template"),
        React.createElement("p", null, "First impression tests are incredibly versatile. They can be used to\u2026"),
        React.createElement("ul", null,
            React.createElement("li", null, "See what catches attention first"),
            React.createElement("li", null, "See if users know how to find key information"),
            React.createElement("li", null, "Gauge trust levels and/or overall brand impression"),
            React.createElement("li", null, "See if users understand what the app/website does"))));
}
