import { __rest } from "tslib";
import * as Tooltip from '@radix-ui/react-tooltip';
import React, { forwardRef, useCallback, useEffect, useRef, useState, } from 'react';
import { useInView } from 'react-intersection-observer';
import { HashtagIcon, UsersIcon } from '@marvelapp/ballpark-icons';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { CardSortThumb } from '../CardSortThumb';
import { FullscreenModal } from '../FullscreenModal';
import { Progress } from '../Progress';
import { CardSortingResponse } from '../Response/CardSorting';
import { Stack } from '../Stack';
import { Table } from '../Table';
import { cn } from '../utils';
import { tableCellEmitter } from './utils';
const CardTitleAndDescription = forwardRef(function CardTitleAndDescription(props, ref) {
    const { align, className, description, title, truncate } = props, rest = __rest(props, ["align", "className", "description", "title", "truncate"]);
    return (React.createElement(Stack, Object.assign({ align: align, className: className, gap: "0.5", ref: ref }, rest),
        React.createElement(BtwHeading, { className: "max-w-full", size: "xs", title: title, truncate: truncate }, title),
        description && (React.createElement(BtwText, { className: "max-w-full", leading: "relaxed", size: "xs", title: description, truncate: truncate }, description))));
});
function AgreementBox(props) {
    const { asTableCell, agreement, className, children, 'data-testid': testid, row, column, } = props;
    const ref = useRef(null);
    const highlightClass = getCellColorClass(agreement);
    useEffect(() => {
        const subscription = tableCellEmitter.subscribe(row, column, (isHighlighted) => {
            if (ref.current) {
                ref.current.classList.toggle(highlightClass.highlight, isHighlighted);
            }
        });
        return subscription;
    }, [column, row, agreement, highlightClass]);
    const classNames = cn(className, highlightClass.resting, {
        'text-blue-900': agreement || agreement === 0,
        'hover:scale-105': row !== -1 && column !== -1,
    });
    const handleMouseEnter = useCallback(() => {
        tableCellEmitter.highlight(row, column);
    }, [column, row]);
    if (asTableCell) {
        return (React.createElement(Table.Td, { className: classNames, "data-testid": testid, onMouseEnter: handleMouseEnter, ref: ref },
            children,
            ' '));
    }
    return (React.createElement(Stack, { align: "center", className: classNames, "data-testid": testid, justify: "center", width: "full", onMouseEnter: handleMouseEnter, ref: ref }, children));
}
function HoverBox(props) {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    return (React.createElement("div", Object.assign({ className: cn('invisible', 'absolute', 'top-0', 'z-10', 'min-h-full', 'w-[150%]', 'rounded-md', 'bg-white', 'p-2.5', 'opacity-0', 'shadow-lg', 'ring-1', 'ring-gray-600/10', 'group-hover:visible', 'group-hover:opacity-100', className) }, rest), children));
}
const AccordionTableRoot = forwardRef(function AccordionTableRoot(props, ref) {
    const { children, type = 'outer' } = props;
    return (React.createElement(Stack, { width: "full", ref: ref, gap: type === 'inner' ? '5' : '0', className: cn({
            '[&>.accordion-table-header]:px-2': type === 'outer',
            '[&>.accordion-table-header]:py-4': type === 'outer',
            '[&>.accordion-table-header]:border-b': type === 'outer',
            '[&>.accordion-table-header]:border-gray-600/20': type === 'outer',
            '[&>.accordion-table-header>.accordion-table-heading]:font-medium': type === 'inner',
            '[&>.accordion-table-header>.accordion-table-heading]:font-semibold': type === 'outer',
        }) }, children));
});
function AccordionTableHeader(props) {
    const { children } = props;
    return (React.createElement(Stack, { gap: "8", direction: "row", asChild: true, width: "full" },
        React.createElement("header", { className: "accordion-table-header" }, children)));
}
function AccordionTableHeading(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(BtwHeading, Object.assign({ size: "xs", className: cn(className, 'accordion-table-heading') }, rest)));
}
function AccordionTableInner(props) {
    return (React.createElement(Stack, { width: "full", gap: "5", className: props.className }, props.children));
}
const AccordionTableRow = forwardRef(function AccordionTableRow(props, ref) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(Stack, Object.assign({ direction: "row", gap: "8", ref: ref, width: "full" }, rest), children));
});
function ColPrimary(props) {
    return (React.createElement(BtwText, { className: props.className, size: "xs", truncate: true, variant: "primary", weight: "medium", "data-testid": props['data-testid'] }, props.children));
}
function ColSecondary(props) {
    return (React.createElement(BtwText, { truncate: true, className: props.className, weight: "normal", size: "xs", "data-testid": props['data-testid'] }, props.children));
}
function IconCol(props) {
    const { children, icon } = props;
    return (React.createElement(BtwText, { align: "left", className: cn('flex', 'items-center', 'gap-1', props.className), size: "xs", truncate: true, weight: "normal" },
        React.createElement("div", { className: "[&>svg>path]:stroke-1 [&>svg]:block [&>svg]:size-3.5" }, icon),
        children));
}
function ColUsers(props) {
    return (React.createElement(IconCol, { className: props.className, icon: React.createElement(UsersIcon, null) }, props.children));
}
function ColNumber(props) {
    return (React.createElement(IconCol, { className: props.className, icon: React.createElement(HashtagIcon, null) }, props.children));
}
function ColProgress(props) {
    const { agreement, className } = props;
    return (React.createElement(Stack, { direction: "row", align: "center", gap: "2.5", className: className },
        React.createElement(Progress.Animated, { variant: getAgreementColor(agreement), size: "sm", value: agreement }),
        React.createElement("div", null,
            React.createElement(BtwText, { className: "w-[46px] text-left", font: "mono", size: "xs", variant: getAgreementColor(agreement), weight: "medium", "data-testid": props['data-testid'] },
                agreement,
                "%"))));
}
function ColEmpty(props) {
    return React.createElement("div", { className: props.className, "data-testid": props['data-testid'] });
}
/**
 * Agreement table
 */
function AgreementTableRoot(props) {
    const { categoriesCount, children } = props;
    const { ref: scrollRef, inView } = useInView({ initialInView: true });
    return (React.createElement(CardSortingResponse.Root, { type: categoriesCount > 4 ? 'always' : 'hover' },
        React.createElement("div", { className: cn('relative', 'pr-8', { 'pb-8': categoriesCount < 5 }) },
            React.createElement("div", { className: "absolute left-0 top-0 h-full w-px", ref: scrollRef }),
            React.createElement(Table, { minWidth: categoriesCount * 150, className: "border-separate border-spacing-1 overflow-auto", "data-testid": props['data-testid'] }, children)),
        React.createElement("div", { className: cn('w-3', 'top-0', 'absolute', 'left-[124px]', 'bottom-[38px]', 'bg-gradient-to-r', 'from-gray-600/10', 'to-transparent', 'pointer-events-none', 
            // Transitions
            'duration-300', 'ease-smooth', 'transition-combined', 'will-change-transform', {
                'opacity-0': inView,
                'opacity-100': !inView,
            }) })));
}
function AgreementTableHeaderItem(props) {
    const { children } = props;
    return (React.createElement("div", { className: "p-1" },
        React.createElement(Stack, { align: "center", className: "h-9", justify: "center" },
            React.createElement(BtwHeading, { size: "xs", truncate: true }, children))));
}
function AgreementTableCell(props) {
    const { agreement = null, align = 'start', className, column = -1, description, row = -1, title, imageSrc, } = props;
    const hasAgreement = agreement !== null && agreement !== undefined;
    return (React.createElement(AgreementBox, { agreement: agreement, asTableCell: true, className: cn('group', 'relative', 'rounded-md', 'py-2.5', className), column: column, "data-testid": props['data-testid'], row: row }, (title || hasAgreement) && (React.createElement(Tooltip.Root, { delayDuration: 0 },
        React.createElement(Tooltip.Trigger, { asChild: true },
            React.createElement(CardTitleAndDescription, { align: align, className: "w-full", description: description, title: title || (agreement !== 0 ? `${agreement}%` : ''), truncate: true })),
        !hasAgreement && title && (title.length > 12 || imageSrc) && (React.createElement(AgreementHoverContent, { align: align, imageSrc: imageSrc },
            React.createElement(CardTitleAndDescription, { align: align, className: "w-full", description: description, title: title || `${agreement}` })))))));
}
function AgreementHoverContent(props) {
    const { align, children, imageSrc } = props;
    // Need to keep track of the open state to ensure the modal stays open when
    // yoou hover away from the tooltip
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(FullscreenModal.Root, { open: isOpen, onOpenChange: setIsOpen },
        React.createElement(Tooltip.Portal, null,
            React.createElement(Tooltip.Content, { align: align, className: cn('-mb-[26px]', 'rounded-md', 'bg-white', 'p-2.5', 'shadow-lg', 'ring-1', 'max-w-md', 'ring-gray-600/10', 'z-10', 'flex', 'gap-1', 'items-center', {
                    '-ml-2.5': align === 'start',
                }) },
                imageSrc && (React.createElement("div", null,
                    React.createElement(FullscreenModal.Trigger, { asChild: true },
                        React.createElement(CardSortThumb.Thumb, { imageSrc: imageSrc, type: "card" })))),
                children)),
        imageSrc && (React.createElement(FullscreenModal.Content, null,
            React.createElement(CardSortThumb.Image, { imageSrc: imageSrc, type: "card" })))));
}
const getAgreementColor = (value) => {
    if (value < 40)
        return 'red';
    if (value < 70)
        return 'yellow';
    return 'green';
};
/**
 * Similarity table
 */
function SimilarityTableRow(props) {
    const { title, imageSrc, similarity, row = -1 } = props;
    return (React.createElement(Stack, { align: "center", direction: "row", width: "full", "data-testid": "similarity-matrix-row", className: "group relative min-w-fit" },
        similarity.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        React.createElement("div", { className: "p-1", key: index },
            React.createElement(AgreementBox, { agreement: value, className: "h-10 w-10 rounded-md", column: index, "data-testid": "similarity-matrix-value", row: row },
                React.createElement(BtwText, { variant: "current", weight: "medium", size: "xs" }, value))))),
        React.createElement(Stack, { align: "center", direction: "row", className: cn('group', 'relative', 'h-10', 'min-w-0', 'flex-1', 'pr-1', 'py-1', {
                'pl-1': row === -1,
                'pl-3': row !== -1,
            }), "data-testid": "similarity-matrix-card" },
            React.createElement(BtwText, { "data-testid": "similarity-matrix-card-title", className: "max-w-full", size: "xs", truncate: true }, title),
            title && (title.length > 12 || imageSrc) && (React.createElement(HoverBox, { className: cn('-ml-1.5', 'max-w-full', 'flex', 'items-center', 'gap-1', {
                    'left-0': row === -1,
                    'left-2': row !== -1,
                }) },
                imageSrc && (React.createElement("div", null,
                    React.createElement(CardSortThumb, { title: title, imageSrc: imageSrc, type: "card" }))),
                React.createElement(BtwText, { align: "left", className: "w-full pt-0.5", size: "xs" }, title))))));
}
const colorRanges = [
    { min: 0, max: 0, resting: 'bg-blue-50', highlight: '!bg-blue-100' },
    { min: 1, max: 39, resting: 'bg-blue-100', highlight: '!bg-blue-200' },
    { min: 40, max: 59, resting: 'bg-blue-200', highlight: '!bg-blue-300' },
    { min: 60, max: 79, resting: 'bg-blue-300', highlight: '!bg-blue-400' },
    { min: 80, max: 100, resting: 'bg-blue-400', highlight: '!bg-blue-500' },
];
const getCellColorClass = (agreement) => {
    if (agreement === null || agreement === undefined) {
        return { resting: '', highlight: '' };
    }
    const range = colorRanges.find((range) => agreement >= range.min && agreement <= range.max);
    return range !== null && range !== void 0 ? range : { resting: '', highlight: '' };
};
const AccordionTable = {
    ColEmpty,
    ColNumber,
    ColPrimary,
    ColProgress,
    ColSecondary,
    ColUsers,
    Header: AccordionTableHeader,
    Heading: AccordionTableHeading,
    Inner: AccordionTableInner,
    Root: AccordionTableRoot,
    Row: AccordionTableRow,
};
const CardSortingAgreementTable = {
    Root: AgreementTableRoot,
    HeaderItem: AgreementTableHeaderItem,
    Cell: AgreementTableCell,
};
const CardSortingSimilarityTable = {
    Row: SimilarityTableRow,
};
export { AccordionTable, CardSortingAgreementTable, CardSortingSimilarityTable, };
