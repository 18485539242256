import React from 'react';
import { CategorySlug } from '../types';
export const date = {
    slug: 'date',
    title: 'Date Test Survey Template | Ballpark',
    metaDescription: "Use our date template to collect consistent data around participant's date of birth or other important dates.",
    heading: 'Date template',
    subheading: 'A simple way to collect dates in your research',
    categories: [CategorySlug.Basics, CategorySlug.Research],
    body: React.createElement(DateBody, null),
    templateUUID: 'ut_6e1749c3-d661-41f8-88c4-24552957ae1e',
    recordLink: 'https://app.ballparkhq.com/record/6e1749c3-d661-41f8-88c4-24552957ae1e',
    accordionTitle: 'Date template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'How do I get a list of all the data gathered from my survey or test?',
            answerParagraphs: [
                'You can download all of the responses, including the date as a CSV allowing you to open it in Google Sheets or Excel.',
            ],
        },
        {
            id: 2,
            question: 'What other formats of data can I collect?',
            answerParagraphs: [
                'You can use our email step to gather email address as well as open-ended questions, multiple choice, rating scale, yes or no and more. ',
            ],
        },
    ],
};
function DateBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "Whether it\u2019s asking a participant\u2019s date of birth or the date they did an action, our date step ensures the data entered is consistent so you\u2019re receiving reliable insights."),
        React.createElement("h3", null, "3 great ways to use our date template"),
        React.createElement("ul", null,
            React.createElement("li", null, "Gather the date of birth of the participant"),
            React.createElement("li", null, "Ask what day someone visited your site"),
            React.createElement("li", null, "Determine when someone bought your product"))));
}
