import React from 'react';
import { CategorySlug } from '../types';
export const onboardingFlowTest = {
    slug: 'onboarding-flow-test',
    title: 'Onboarding Flow Test Template | Ballpark ',
    metaDescription: 'Improve your product usage with this onboarding flow template. See how your users sign up and engage with functionality so you can identify optimisations.',
    heading: 'Onboarding flow test template',
    subheading: 'Understand how you can improve your customers first-time use experience',
    categories: [CategorySlug.PrototypeTesting, CategorySlug.DesignAndProduct],
    body: React.createElement(OnboardingFlowTestBody, null),
    templateUUID: 'ut_fd8c1722-bfa1-4563-afeb-45236d1f9138',
    recordLink: 'https://app.ballparkhq.com/record/fd8c1722-bfa1-4563-afeb-45236d1f9138',
    accordionTitle: 'Onboarding flow template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'Can I add multiple onboarding flows to the same test?',
            answerParagraphs: [
                'Yes! Ballpark supports adding multiple prototypes so you can test dozens of variations of your onboarding flow within the same test.',
            ],
        },
        {
            id: 2,
            question: 'What are some different things I can test for onboarding?',
            answerParagraphs: [
                'The most common is to test the performance of showcasing new features in the onboarding flow vs showing a video.',
                'Highlighting the features allows you to show exactly what to click on in the product but may become overwhelming if your product is complicated. A video may be more useful for users who prefer to learn in their own time, but may not convert as well straight after sign-up.',
            ],
        },
    ],
};
function OnboardingFlowTestBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "Product onboarding is the process of introducing your features, app or website to users after they sign-up."),
        React.createElement("p", null,
            "A great onboarding experience allows a user to quickly understand how to use your core features and hopefully, become activated and retained.",
            ' '),
        React.createElement("p", null, "Often the best time to do this is within seconds of that user signing up to your product as that is when you have their maximum (yet limited) attention."),
        React.createElement("p", null, "Get it right and they could be your next power user!"),
        React.createElement("p", null, "This template gives you an example of how you can use a Figma prototype embedded into Ballpark to test your onboarding flow before going into production. You can ask questions along the way and get feedback on the participants\u2019 experiences."),
        React.createElement("h3", null, "5 great ways to use our onboarding flow template"),
        React.createElement("ul", null,
            React.createElement("li", null, "Refine and optimise your onboarding flow before going live"),
            React.createElement("li", null, "Discover whether the design of your onboarding flow is likely to convert users by tracking tasks and goal completion"),
            React.createElement("li", null, "Track duration to complete the goal of signing up and create a benchmark"),
            React.createElement("li", null, "Follow-up with targeted survey questions about their experience signing up"),
            React.createElement("li", null, "Test your competitors' website sign-up flows and discover their strengths and weaknesses"))));
}
