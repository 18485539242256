import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { Table as TablePrimitive } from '../Table';
import { cn } from '../utils';
const Table = forwardRef(function Table(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(TablePrimitive, Object.assign({ className: cn('table-auto', 'overflow-auto', 'border-separate', 'caption-bottom', 'group/table', 'text-13', className), minWidth: 0, ref: ref }, rest)));
});
const TableHeader = TablePrimitive.Thead;
const TableBody = TablePrimitive.Tbody;
const TableFooter = forwardRef(function TableFooter(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(TablePrimitive.Tfoot, Object.assign({ className: cn('bg-primary', 'font-medium', 'text-primary-foreground', className), ref: ref }, rest)));
});
const TableRow = forwardRef(function TableRow(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(TablePrimitive.Tr, Object.assign({ className: cn('group', className), ref: ref }, rest)));
});
const TableHead = forwardRef(function TableHead(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(TablePrimitive.Th, Object.assign({ className: cn(
        // Using this classic h-0 hack here to make sure its child divs can grow full height.
        // It's a weird thing, but literally no other value for height will do.
        'h-[inherit] border-b border-gray-600/10 px-5 py-5 text-left align-middle', 
        // Changing the bottom border color, if the first (visible) table row happens to be selected
        'group-has-[tr[data-state=selected]:nth-of-type(2)]/table:border-b-blue-300', 
        // Like above, but if the first row is highlighted
        'group-has-[tr[data-highlighted=true]:nth-of-type(2)]/table:border-b-blue-300', className), ref: ref }, rest)));
});
const TableCell = forwardRef(function TableCell(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(TablePrimitive.Td, Object.assign({ className: cn(
        // Firstly, we're using borders on table cells instead of rows, because we need the borders to stick on sticky header and first column cells.
        // That can only be accomplished with border-separate applied on the table, because with border-collapse (the default on tables), the borders will scroll.
        // And with border-separate enabled, table rows don't display borders. Hence, borders on cells.
        'border-b border-gray-600/10 px-5 py-2.5 align-middle group-hover:bg-gray-50 ', 
        // And here we want to apply border and background styling to selected rows. Selected rows will have a data attribute added, and
        // here we add overrides to cells whose parents (group is a Tailwind group modifier, which is applied above on the row level) have
        // said attribute. However, we also need to select the row immediately before the selected row to change its border-bottom color as well.
        // This results in the absolute chonker selectors below.
        'group-[:has(+[data-state=selected])]:border-b-blue-300 group-data-[state=selected]:border-b-blue-300 group-data-[state=selected]:bg-blue-50', 
        // Styling for highlighted rows (e.g., recruitment orders table)
        'group-[:has(+[data-highlighted=true])]:border-b-gray-300 group-data-[highlighted=true]:border-b-gray-300 group-data-[highlighted=true]:bg-gray-50', 
        // Adding this Talwind peer modified to apply the same blue borders to the
        'peer', 
        // Adding this group-level modifier to use hover on children
        'group/cell', 
        // Making cells relative to absolutely position children
        'relative', className), ref: ref }, rest)));
});
const TableCaption = forwardRef(function TableCaption(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(TablePrimitive.Caption, Object.assign({ className: cn('text-sm', 'whitespace-nowrap', 'text-muted-foreground', className), ref: ref }, rest)));
});
export { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow, };
