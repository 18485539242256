import React from 'react';
import { CategorySlug } from '../types';
export const customerFeedbackQuestionnaire = {
    slug: 'customer-feedback-questionnaire',
    title: 'Customer Feedback Questionnaire Template | Ballpark',
    metaDescription: 'Measure your voice of the customer by gathering feedback with this customer feedback questionnaire template. Validate product decisions with the customer insights gathered.',
    heading: 'Customer feedback questionnaire template',
    subheading: 'Discover product needs or validate product decisions',
    categories: [
        CategorySlug.Survey,
        CategorySlug.CustomerSuccess,
        CategorySlug.ProductFeedback,
    ],
    body: React.createElement(CustomerFeedbackQuestionnaireBody, null),
    templateUUID: 'ut_47a539fd-8964-4ce1-b55c-c22d697cbb12',
    recordLink: 'https://app.ballparkhq.com/record/47a539fd-8964-4ce1-b55c-c22d697cbb12',
    accordionTitle: 'Customer feedback questionnaire FAQs ',
    accordionItems: [
        {
            id: 1,
            question: 'How can I encourage people to fill out a questionnaire?',
            answerParagraphs: [
                'Respondents can be hesitant to spend their time filling out questionnaires. To increase the number of responses, try emphasizing why you’re asking the questions, the improvements you’re trying to make, and how those improvements could benefit the respondent’s experience. You could also list examples of improvements that you’ve made in the past as a result of customer feedback, as proof that their feedback could truly make a difference.',
                'If you have the budget, you could even run a competition where one random respondent (or the first <x> number of respondents) receives a prize such as a voucher.',
                'You may also want to think about the timing of the questionnaire. People tend to ignore unessential requests if the timing doesn’t suit them, so if you’re able to target respondents at the end of their work day for example (according to their timezone), that could yield more responses compared to a mid-day request when respondents are busier.',
            ],
        },
        {
            id: 2,
            question: 'At which point in the customer’s lifecycle should I send a customer feedback questionnaire?',
            answerParagraphs: [
                'The timing of when you send a customer feedback questionnaire could affect the quality of the insights gathered. If they haven’t logged in recently or haven’t used any of the key features, you’ll still want to get their feedback but you should definitely segment them from those who have used the product more actively.',
                'And finally, you might want to try sending a reminder to those that didn’t complete the questionnaire, but perhaps only once as you don’t want to appear pushy.',
            ],
        },
    ],
};
function CustomerFeedbackQuestionnaireBody() {
    return (React.createElement("div", null,
        React.createElement("h3", null, "What is a customer feedback questionnaire?"),
        React.createElement("p", null, "A customer feedback questionnaire is a product-focused questionnaire that helps organizations listen to the voices of their customers and stay on top of their wants, needs, likes, dislikes, and overall opinions and feedback."),
        React.createElement("h3", null, "Why are customer feedback questionnaires used?"),
        React.createElement("p", null, "Customer feedback questionnaires are used to discover product needs (or validate product decisions that were made based on feedback from earlier customer feedback questionnaires), improving the customer and/or user experience (or helping it to stay awesome!)."),
        React.createElement("p", null, "It\u2019s important to conduct customer feedback questionnaires on a regular basis as customer and user needs may change over time and it\u2019s critical for organizations to be able to pivot when necessary."),
        React.createElement("h3", null, "Who uses customer feedback questionnaires?"),
        React.createElement("p", null, "Anybody that\u2019s responsible for the product experience (or a part of it) can use customer feedback questionnaires to better understand who uses the product, what they use it for, what they think and feel about it, and what they would change about it (if anything)."),
        React.createElement("h3", null, "What types of data does the customer feedback questionnaire yield?"),
        React.createElement("p", null, "Our customer feedback questionnaire template yields a mix of quantitative data (which can be used to track improvement over time) and qualitative data (which can be used to make informed decisions about the product). The two types of data combined make this questionnaire a customer satisfaction questionnaire but with useful insights and added context that explain the reasoning behind the respondents satisfaction levels."),
        React.createElement("h3", null, "What questions are in the customer feedback questionnaire template?"),
        React.createElement("p", null, "The first and overarching question is \u201COverall, how satisfied or dissatisfied are you with our product?\u201D, a quantitative question that customers can answer on a scale ranging from 1 (meaning \u201CVery dissatisfied\u201D) to 5 (meaning \u201CVery satisfied\u201D), with an ambivalent \u201CNeither satisfied nor dissatisfied\u201D answer in the middle. The averaged answer to this question is the most important metric for tracking improvement over time."),
        React.createElement("p", null, "The second question, \u201CWhich of the following would you use to describe our product? You can select multiple.\u201D, helps to narrow down what\u2019s good or bad about the product overall, conveniently providing respondents with options to answer with (e.g. \u201CHigh quality\u201D or \u201CGood value for money\u201D) without enabling them to go into too much detail. This question can be qualitative and quantitative."),
        React.createElement("p", null, "The set of questions that follow relate to how well the product meets customer or user needs as well as its quality and value for money, all of which are quantitative \u2018on a scale of 1-5\u2019 questions."),
        React.createElement("p", null, "To wrap up the questionnaire, there are also some \u2018added context\u2019 questions such as \u201CHow long have you been a customer of our product?\u201D (users of B2B products can answer with \u201CI\u2019m not a customer\u201D) and \u201CHow would you rate the support you have received?\u201D."),
        React.createElement("p", null, "In addition to the standard template questions, you might want to customize the template by adding some extra questions to gain even more insight. For example, here are some questions that can provide more qualitative insight:"),
        React.createElement("ul", null,
            React.createElement("li", null, "What challenges are you trying to solve with this product?"),
            React.createElement("li", null, "What drove you to look for an alternative tool?"),
            React.createElement("li", null, "Which features do you use the most?"),
            React.createElement("li", null, "What is stopping you from using this product more frequently?")),
        React.createElement("p", null, "This template is suitable for Business-to-Consumer (B2C) products where users acquire the product for themselves and Business-to-Business (B2B) products where the customer is an organization that acquires the product on behalf of its users. Both users and customers should be able to answer the majority of the questions, however they may answer from different perspectives (especially where value for money is concerned), so you might want to ask respondents whether they\u2019re a user or a customer and then segment the results based on this."),
        React.createElement("p", null, "For more granular feedback you could also ask about the respondents role, since even different roles can have wildly different feelings about a product. For example, a manager that checks timesheets would use a time tracking app very differently to somebody that\u2019s required to log their time.")));
}
