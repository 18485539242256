import React from 'react';
import { CategorySlug } from '../types';
export const email = {
    slug: 'email',
    title: 'Email Survey Template | Ballpark',
    metaDescription: 'Collect email address for lead generation or for follow up for additional research with our email step template.',
    heading: 'Email template',
    subheading: 'A simple way to collect emails for list building',
    categories: [CategorySlug.Basics, CategorySlug.Research],
    body: React.createElement(EmailBody, null),
    templateUUID: 'ut_fb3ea95b-713a-4954-8a06-3a1181da1f8b',
    recordLink: 'https://app.ballparkhq.com/record/fb3ea95b-713a-4954-8a06-3a1181da1f8b',
    accordionTitle: 'Email template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'How do I get a list of all the email addresses gathered from my survey or test?',
            answerParagraphs: [
                'You can download all of the responses, including the email addresses as a CSV allowing you to open it in Google Sheets or Excel.',
            ],
        },
    ],
};
function EmailBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "Whether you are collecting email addresses to follow-up with your participants or building a list of early adopters before launching, our email step ensures addresses are correctly formatted so you know you\u2019re collecting accurate information each time."),
        React.createElement("h3", null, "4 great ways to use our email template"),
        React.createElement("ul", null,
            React.createElement("li", null, "Gather email addresses from survey respondents, allowing you to follow up with additional questions and tasks"),
            React.createElement("li", null, "Send a reward (such as a voucher) for taking part in your survey to the email provided"),
            React.createElement("li", null, "Build an audience for your product or service before building it (prototypes are a great way to give people an example of what\u2019s in store, without needing to write code)"),
            React.createElement("li", null, "Track the performance of a particular set of participant"))));
}
