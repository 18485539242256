import React from 'react';
import { Link } from '../Link';
import { CategorySlug } from '../types';
export const priceSensitivitySurvey = {
    slug: 'price-sensitivity-survey',
    title: 'Price Sensitivity Survey Template | Ballpark',
    metaDescription: 'Discover the ideal price point for your product with our price sensitivity survey template. Ensure competitiveness and uncover new pricing opportunties.',
    heading: 'Price sensitivity survey template',
    subheading: 'Discover how to price a product or service',
    categories: [
        CategorySlug.Popular,
        CategorySlug.Marketing,
        CategorySlug.ProductFeedback,
    ],
    body: React.createElement(PriceSensitivityBody, null),
    templateUUID: 'ut_1a7ac3b7-13d6-4e00-94ad-b6ccdb45da01',
    recordLink: 'https://app.ballparkhq.com/record/1a7ac3b7-13d6-4e00-94ad-b6ccdb45da01',
    accordionTitle: 'Price sensitivity survey FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'When should you conduct a price sensitivity survey?',
            answerParagraphs: [
                'All products and services should conduct a price sensitivity survey to discover their initial pricing.',
                'After the initial price sensitivity survey it depends on the competitiveness of the market. If new rival products are frequently appearing on the market or the competition is changing their product offering often, then price sensitivity surveys should be conducted more frequently.',
                'Particularly, if a competitor changes their price you should probably conduct a price sensitivity survey in order to ensure that you’re still competitive.',
            ],
        },
    ],
};
function PriceSensitivityBody() {
    return (React.createElement("div", null,
        React.createElement("p", null,
            React.createElement(Link, { href: "https://en.wikipedia.org/wiki/Van_Westendorp%27s_Price_Sensitivity_Meter", target: "_blank" }, "Van Westendorp\u2019s"),
            ' ',
            "price sensitivity survey or meter asks people at which price they would deem the product to be too expensive, too cheap, nearing expensive, and a bargain. This approach is better than 'price laddering', a survey that tends to present itself as more of a negotiation, causing users to show bias towards cheaper pricing."),
        React.createElement("p", null, "Our price sensitivity survey template, which takes the Van Westendorp approach, asks the following 4 questions (in this specific order, to prevent the aforementioned bias):"),
        React.createElement("ol", null,
            React.createElement("li", null, "At what price would you consider the product to be so expensive that you wouldn\u2019t consider buying it?"),
            React.createElement("li", null, "At what price would you consider the product to be so cheap that the quality might not be good?"),
            React.createElement("li", null, "At what price would you consider the product is starting to get expensive and you would have to think before buying it?"),
            React.createElement("li", null, "At what price would you consider the product to be a bargain?")),
        React.createElement("h3", null, "3 great ways to use our price sensitivity survey template"),
        React.createElement("ul", null,
            React.createElement("li", null, "Discover how to price a new product"),
            React.createElement("li", null, "Discover how to adjust the pricing of an existing product to remain competitive in the market"),
            React.createElement("li", null, "Discover whether a product, feature or idea is financial viable"))));
}
