import React from 'react';
import { NestedList } from '../components/NestedList';
import { CategorySlug } from '../types';
export const systemUsabilityScale = {
    slug: 'system-usability-scale',
    title: 'System Usability Scale Template | Ballpark',
    metaDescription: 'Using a system usability scale survey template will score usability and highlight areas of improvement. Helping improve usability that can impact active use.',
    heading: 'System Usability Scale (SUS) template',
    subheading: 'Measure the overall usability of products or services',
    categories: [
        CategorySlug.Popular,
        CategorySlug.ProductFeedback,
        CategorySlug.DesignAndProduct,
    ],
    body: React.createElement(SystemUsabilityScaleBody, null),
    templateUUID: 'ut_a332bb44-8bd7-499b-9e93-06a74897ec32',
    recordLink: 'https://app.ballparkhq.com/record/a332bb44-8bd7-499b-9e93-06a74897ec32',
    accordionTitle: 'System usability scale FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'What is considered a good system usability scale score?',
            answerParagraphs: [
                'Usability is important to all users, but its importance varies depending on the user and the product. It’s up to you to determine at which point/score usability starts to significantly impact Key Performance Indicators (KPIs). That being said, a system usability scale score above 68/100 is considered ‘above average.’',
            ],
        },
        {
            id: 2,
            question: 'Is the system usability scale useful and reliable?',
            answerParagraphs: [
                'The system usability scale is both useful and reliable as long as its used correctly.',
                'It’s useful as long as it’s used as a jumping off point for usability research with special attention being paid to areas that are lacking.',
                'It’s reliable as long as enough respondents are recruited (at least 15). Considering how quick and easy it is for users to participate in the study, finding 15 users/respondents shouldn’t be an issue.',
                'In fact, there’s absolutely no harm in recruiting as many respondents as possible.',
            ],
        },
    ],
};
function SystemUsabilityScaleBody() {
    return (React.createElement("div", null,
        React.createElement("h3", null, "What is a System Usability Scale (SUS)?"),
        React.createElement("p", null, "The System Usability Scale (SUS) was the brainchild of John Brooke back in 1986. He created this \u201Cquick and dirty\u201D tool to be able to measure the usability of a product or design. It has since been used to evaluate almost any kind of system including apps, hardware, mobile devices, software, and websites."),
        React.createElement("p", null,
            "The survey consists of 10 statements, such as, \u201CI found the product unnecessarily complex\u201D and \u201CI felt very confident using the product\u201D. Respondents are expected to rate these statements on a scale of 1 to 5 based on how much they agree with which statement is presented to them. A score of 1 on the Likert Scale would indicate that the participant",
            ' ',
            React.createElement("b", null, "disagreed strongly"),
            " and a score of 5 would mean they",
            ' ',
            React.createElement("b", null, "agreed completely"),
            ". Each respondent\u2019s total answers are then equated to a score between 1-100 - we\u2019ll touch on this more later."),
        React.createElement("p", null, "The System Usability Scale has been tried and tested for over 30 years, and has proven itself to be a dependable and valid method of evaluating the perceived usability of systems. So much so that it\u2019s highly likely designers and researchers will still be counting on the System Usability Scale in many many years to come. Its ease and range of use as well as its versatility are also why it remains a popular usability test."),
        React.createElement("h3", null, "When should you ask the System Usability Scale questions?"),
        React.createElement("p", null, "Incorporating the System Usability Scale should happen once you\u2019ve developed your prototype and you\u2019re ready to test."),
        React.createElement("p", null, "You should include the 10 questionnaire type questions at the end of your Ballpark usability test to see how users interacted with your design or prototype and measure their experience."),
        React.createElement("p", null, "As the system usability scale is a consistent tool, it can either be used across multiple tests allowing you to compare scores across designs, for example in A/B testing with two different designs, or yours against a competitors. Or it can be used to compare scores over time, for example monthly or quarterly."),
        React.createElement("h3", null, "3 great ways to use our system usability scale template"),
        React.createElement("ol", null,
            React.createElement("li", null, "Determine the urgency of usability improvements"),
            React.createElement("li", null, "Discover which aspects of usability need attention"),
            React.createElement("li", null, "Measure usability improvement/diminishment over time")),
        React.createElement("h3", null, "Benefits of using the System Usability Scale (SUS)"),
        React.createElement("p", null, "The system usability scale:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Is quick and easy to scale and administer to participants - especially online - as the hard work has already been done for you, also making it cheaper than a lot of other usability tests"),
            React.createElement("li", null, "Can be used on small sample sizes with reliable results (we\u2019d recommend a minimum of 5 users)"),
            React.createElement("li", null, "Is quick and easy for users to complete so you should have results within 1 to 2 minutes"),
            React.createElement("li", null, "It\u2019s statistically valid, solid and reliable \u2013 it measures exactly what it\u2019s meant to and can effectively and efficiently highlight unusable products or services")),
        React.createElement("h3", null, "The System Usability Scale (SUS) is not diagnostic"),
        React.createElement("p", null, "System Usability Scale scores are not diagnostic. The results will not be able to diagnose any specific issues or provide context as to why each user responded the way they did but it does give you the green or red light about the usability of your design. Despite this subjective nature, it\u2019s still a great tool for usability benchmarking, determining which aspects of the product or service need attention and improving, and seeing how you fare against your competitors."),
        React.createElement("h3", null, "Interpreting System Usability Scale scores"),
        React.createElement("p", null, "Interpreting the system usability scale scoring can be a complex task. We mentioned before that each respondent rates a statement on a scale of 1 to 5 and that the total answers are then converted to a new score between 1 and 100."),
        React.createElement("p", null, "The first thing to point out is that this conversion produces a percentile ranking - not to be confused with a percentage!"),
        React.createElement("p", null, "Each response is assigned a value for the System Usability Scale score calculation. The breakdown of points for the responses are:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Strongly Disagree: 1 point"),
            React.createElement("li", null, "Disagree: 2 points"),
            React.createElement("li", null, "Neutral: 3 points"),
            React.createElement("li", null, "Agree: 4 points"),
            React.createElement("li", null, "Strongly Agree: 5 points")),
        React.createElement("p", null, "And how do you convert the scores to a number between 1 and 100 you might ask? Here\u2019s a simple step-by-step guide:"),
        React.createElement("ol", null,
            React.createElement("li", null, "For the odd numbered questions (questions 1, 3, 5, 7, and 9), add together the total score for each and then subtract 5 from the total = X"),
            React.createElement("li", null, "For the even numbered questions (questions 2, 4, 6, 8, and 10), sum up the total score for all questions, then take away that number from 25 = Y"),
            React.createElement("li", null, "Take these new values which you have found, and add up the total score. Then multiply this by 2.5 i.e. (X + Y) x 2.5")),
        React.createElement("h3", null, "Example:"),
        React.createElement(NestedList, null,
            React.createElement("li", null,
                "Odd question responses = (3+4+5+2+3) = X = 17",
                React.createElement("ul", null,
                    React.createElement("li", null, "17 \u2013 5 = 12"))),
            React.createElement("li", null,
                "Even question responses = (3+1+3+1+2) = Y = 10",
                React.createElement("ul", null,
                    React.createElement("li", null, "25 \u2013 10 = 15"))),
            React.createElement("li", null,
                "SUS Score = (X + Y) x 2.5",
                React.createElement("ul", null,
                    React.createElement("li", null, "(17+10) x 2.5 = 67.5")))),
        React.createElement("p", null, "The average system usability scale score is 68. Simply put, a score of 68 will put the usability of your product or service in the 50th percentile."),
        React.createElement("h3", null, "Here\u2019s how to interpret your scores:"),
        React.createElement(NestedList, null,
            React.createElement("li", null,
                "80.3 or higher is excellent - well done you!",
                React.createElement("ul", null,
                    React.createElement("li", null, "You can be confident that users love your product and will recommend it to others."))),
            React.createElement("li", null,
                "68 or thereabouts is okay - nothing to celebrate just yet!",
                React.createElement("ul", null,
                    React.createElement("li", null, "There are definitely some aspects of usability that need attention and improvement."))),
            React.createElement("li", null,
                "51 or under is poor - your product or service needs some improvements in terms of usability.",
                React.createElement("ul", null,
                    React.createElement("li", null, "Remedying your designs should be an urgent priority.")))),
        React.createElement("h3", null, "Ballpark\u2019s System Usability Scale (SUS) template"),
        React.createElement("p", null, "Ballpark\u2019s system usability scale template is designed so you can jump straight into testing your usability. This template includes all 10 of John Brooke\u2019s questions which you can personalise and add your own context or explanation which will help the user take the test accurately."),
        React.createElement("p", null, "Here\u2019s a list of the questions included:"),
        React.createElement("ol", null,
            React.createElement("li", null, "I think that I would like to use this system frequently."),
            React.createElement("li", null, "I found the system unnecessarily complex."),
            React.createElement("li", null, "I thought the system was easy to use."),
            React.createElement("li", null, "I think that I would need the support of a technical person to be able to use this system."),
            React.createElement("li", null, "I found the various functions in this system were well integrated."),
            React.createElement("li", null, "I thought there was too much inconsistency in this system."),
            React.createElement("li", null, "I would imagine that most people would learn to use this system very quickly."),
            React.createElement("li", null, "I found the system very cumbersome to use."),
            React.createElement("li", null, "I felt very confident using the system."),
            React.createElement("li", null, "I needed to learn a lot of things before I could get going with this system."))));
}
