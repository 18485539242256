import React from 'react';
import { CategorySlug } from '../types';
export const yesNoQuestion = {
    slug: 'yes-no-question',
    title: 'Yes or No Survey Test Template | Ballpark',
    metaDescription: 'Get your research started with a close ended question like a yes or no. This test template will show you how you can easily gather results and data.',
    heading: 'Yes/no question template',
    subheading: 'A simple ‘yes or no’ question',
    categories: [CategorySlug.Basics, CategorySlug.Research],
    body: React.createElement(YesNoQuestionBody, null),
    templateUUID: 'ut_45766f50-d18f-4336-8fd3-79b88dc330b0',
    recordLink: 'https://app.ballparkhq.com/record/45766f50-d18f-4336-8fd3-79b88dc330b0',
    accordionTitle: 'Yes/no question FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'When are yes/no questions better than open-ended questions?',
            answerParagraphs: [
                'Yes/no answers can tell us what, but not why. However, yes/no responses are sometimes all that’s needed. Other times, asking users questions that aren’t as important might seem bothersome, so pick your battles, opt for yes/no questions instead, and save more intense research methods for more critical questions.',
                'Besides, you can always follow up a yes/no question with an open-ended question later on.',
            ],
        },
        {
            id: 2,
            question: 'Can yes/no questions save time?',
            answerParagraphs: [
                'Yes! When building products, teams may contemplate hundreds of important and non-important decisions without even realising, so yes/no questions can save a lot of cognitive energy and time. Avoid wondering whether or not users will like ‘that’ font and leave the decision to a simple yes/no question instead.',
            ],
        },
    ],
};
function YesNoQuestionBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "Yes/no questions are simple closed-ended questions that yield quantifiable answers, which are ideal for making quick decisions."),
        React.createElement("h3", null, "3 great ways to use our yes/no question template"),
        React.createElement("p", null, "Yes/no questions can be used in many scenarios:"),
        React.createElement("ul", null,
            React.createElement("li", null, `Product needs: “Would you use something that does <this>?”`),
            React.createElement("li", null, `User needs: “Does <this> solve your issue?”`),
            React.createElement("li", null, `UX needs: “Is <this> clear?”`))));
}
