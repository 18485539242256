import React from 'react';
import { CategorySlug } from '../types';
export const firstClickTest = {
    slug: 'first-click-test',
    title: 'First Click Test Template | Ballpark',
    metaDescription: 'See how well a task is performed with a first click test template. Where a user first clicks can provide insight into the usability of a website or product.',
    heading: 'First click test template',
    subheading: 'See how well users are able to find something',
    categories: [CategorySlug.Basics, CategorySlug.Research],
    body: React.createElement(FirstClickTestBody, null),
    templateUUID: 'ut_f308b82d-d91d-4704-9e0d-86f295be1052',
    recordLink: 'https://app.ballparkhq.com/record/f308b82d-d91d-4704-9e0d-86f295be1052',
    accordionTitle: 'First click test FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'What happens when users fail first click tests?',
            answerParagraphs: [
                'First click tests are better for discovering design flaws rather than diagnosing them. While respondents will be asked why they chose their answer, the solution (if one is needed) isn’t always obvious. Additional research (which can mean more first click testing) should be used to clarify the reason(s) why users are having difficulty and/or test an alternative solution.',
            ],
        },
        {
            id: 2,
            question: 'Should ‘time to first click’ be low?',
            answerParagraphs: [
                'If the time to first click (TTFC) is low and the success rate is also low, this shows that users are certain that they’re making the right choice even though they’re not. This is the only scenario where a low TTFC is a bad thing.',
                'As is the case with many types of datasets, it’s important to analyse what the data really means as opposed to taking it at face value.',
            ],
        },
    ],
};
function FirstClickTestBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "First click tests reveal how well users are able to find something (initiate the first step of a user or task flow). They indicate if something is wrong with the UX copy, location, or visual affordance of the tap target(s). If the user or task flow can be initiated in many ways, the most favoured one will become obvious."),
        React.createElement("p", null, "Quantitative metrics include \u2018time to first click\u2019 and \u2018success rate\u2019, both of which can be tracked to help measure improvement over time."),
        React.createElement("p", null, "Qualitative metrics include written feedback."),
        React.createElement("h3", null, "3 great ways to use our first click test template"),
        React.createElement("ul", null,
            React.createElement("li", null, "See if users are able to find something"),
            React.createElement("li", null, "Quantitatively track improvement over time"),
            React.createElement("li", null, "Get a rough idea of what needs to change (if anything) based on users\u2019 behaviours and an even better idea based on written feedback"))));
}
