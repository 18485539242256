import React from 'react';
import { CategorySlug } from '../types';
export const speakOutLoudScreener = {
    slug: 'speak-out-loud-screener',
    title: 'Speak-out-loud screener | Ballpark',
    metaDescription: 'A screener template to confirm that participants are set up and prepared to deliver audio and video feedback.',
    heading: 'Speak-out-loud screener',
    subheading: 'Confirm that participants are set up and prepared to deliver audio and video feedback',
    categories: [CategorySlug.Screeners],
    body: React.createElement(SpeakOutLoudScreenerBody, null),
    templateUUID: 'ut_6719caf3-f182-4f26-ac11-c0b1d412bdc9',
    recordLink: 'https://app.ballparkhq.com/record/6719caf3-f182-4f26-ac11-c0b1d412bdc9',
    accordionTitle: '',
    accordionItems: [],
};
function SpeakOutLoudScreenerBody() {
    return (React.createElement("div", null,
        React.createElement("h3", null, "What is a screener?"),
        React.createElement("p", null, "Screeners are preliminary surveys or questionnaires used to select suitable participants for research studies or surveys. They help ensure that participants meet specific criteria and are prepared to engage in the research process effectively. For example, a screener for an unmoderated research study might require participants to have a quiet environment and a functional microphone to provide clear audio feedback. Screeners filter out individuals who do not meet these criteria, ensuring high-quality and relevant data collection for the study."),
        React.createElement("h3", null, "What does this screener do?"),
        React.createElement("p", null, "This is what we call a \u2018quality control screener\u2019 for a Speak-out-loud usability test that ensures participants meet specific criteria and are prepared to provide clear, audible feedback."),
        React.createElement("p", null, "Key components include:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Quiet Environment: Participants must be in a noise-free setting."),
            React.createElement("li", null, "Working Microphone: Ensure a functional microphone for clear audio."),
            React.createElement("li", null, "Speak Out Loud: Participants need to vocalize their thoughts when prompted."),
            React.createElement("li", null, "Acknowledge Requirements: Participants confirm they understand and agree to these conditions.")),
        React.createElement("h3", null, "When should I use a screener like this?"),
        React.createElement("p", null, "A speak-out-loud screener is used before participants start a talk out loud usability test to ensure they meet the necessary criteria for providing clear and valuable feedback. This is typically employed in the following scenarios:"),
        React.createElement("ol", null,
            React.createElement("li", null, "Pre-Study Screening: Before the main usability test to filter out unsuitable participants."),
            React.createElement("li", null, "Remote Testing: Ensuring participants have the right environment and equipment for remote tests."),
            React.createElement("li", null, "Quality Assurance: To maintain high standards in feedback quality by verifying participants are ready and able to comply with the test requirements."))));
}
