export const CategoryName = {
    Basics: 'The basics',
    BrandAndCopyTesting: 'Brand and copy testing',
    CustomerSuccess: 'Customer Success',
    DesignAndProduct: 'Design and product',
    HighConversion: 'High conversion',
    Marketing: 'Marketing',
    Popular: 'Popular',
    ProductFeedback: 'Product feedback',
    PrototypeTesting: 'Prototype testing',
    Research: 'Research',
    Sales: 'Sales',
    Screeners: 'Screeners',
    Survey: 'Survey',
    VideoGuides: 'Video guides',
    WebsiteTesting: 'Website testing',
};
export const CategorySlug = {
    Basics: 'basics',
    BrandAndCopyTesting: 'brand-and-copy-testing',
    CustomerSuccess: 'customer-success',
    DesignAndProduct: 'design-and-product',
    HighConversion: 'high-conversion',
    Marketing: 'marketing',
    Popular: 'popular',
    ProductFeedback: 'product-feedback',
    PrototypeTesting: 'prototype-testing',
    Research: 'research',
    Sales: 'sales',
    Screeners: 'screeners',
    Survey: 'survey',
    VideoGuides: 'video-guides',
    WebsiteTesting: 'website-testing',
};
export var CategoryGroupSlug;
(function (CategoryGroupSlug) {
    CategoryGroupSlug["Departments"] = "departments";
    CategoryGroupSlug["Featured"] = "featured";
    CategoryGroupSlug["UseCases"] = "use-cases";
})(CategoryGroupSlug || (CategoryGroupSlug = {}));
