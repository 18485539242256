import React from 'react';
import styled from '@marvelapp/styled';
import { CategorySlug } from '../types';
export const competitorPaidSearchTest = {
    slug: 'competitor-paid-search-test',
    title: 'Competitor Paid Search Test Template | Ballpark',
    metaDescription: 'Measure how your paid search adverts compare to your competitor in memorability and likelihood to convert with our competitor paid search test template.',
    heading: 'Competitor website usability test template',
    subheading: 'Gain insight into your competitor’s website usability (and more)',
    categories: [CategorySlug.WebsiteTesting, CategorySlug.Marketing],
    body: React.createElement(CompetitorPaidSearchTestBody, null),
    templateUUID: 'ut_0c133822-b263-4be9-83ba-1908725dfea8',
    recordLink: 'https://app.ballparkhq.com/record/0c133822-b263-4be9-83ba-1908725dfea8',
    accordionTitle: 'Competitor search test template FAQs ',
    accordionItems: [
        {
            id: 1,
            question: 'How does the competitor website usability test actually work?',
            answerParagraphs: [
                'To begin, respondents are prompted to give their web browser permission to access their camera and microphone as well as permission to record their screen, after which they can then proceed with the test and questionnaire. When the time comes, the website in question will open in a new tab and respondents will be recorded as they attempt to complete the set task.',
                'Once respondents are ready to move on, they can either close the tab or simply return to the test/questionnaire tab and begin answering any follow-up questions.',
            ],
        },
        {
            id: 2,
            question: 'Is the competitor website usability test easy for respondents to take?',
            answerParagraphs: [
                'Yes, very easy. Even though respondents will need to permit their web browser to access their camera and microphone, and record their screen, it’s a fairly quick and non-invasive prompt. Worst-case scenario is that macOS users will need to enable permissions at the OS level, which after doing so would require them to restart their browser. Most web browsers restore tabs after quitting, so respondents should have no issue getting back into the test/questionnaire. If for some reason there is an issue, respondents can click on their invite link to start over.',
            ],
        },
        {
            id: 3,
            question: 'What types of respondents should I recruit to participate in competitor website usability tests?',
            answerParagraphs: [
                'There are many different types of people that you can invite to participate. Firstly, if you already have an existing website, you can invite your own users to see what they do and don’t find appealing about your competitor’s website. Secondly, you can do the same thing with their users, finding out what it would take to acquire them as customers. Finally, you could invite people from your target audience that aren’t users of your website or your competitor’s.',
                'Ideally, you’ll want to utilize all of them. Just remember to segment the results by group (you can use your preliminary question for this), since different groups might report different feedback, which is an insight in itself.',
            ],
        },
        {
            id: 4,
            question: 'What types of respondents should I recruit to participate in competitor website usability tests?',
            answerParagraphs: [
                'Competitor website usability tests are designed to result in an insane number of useful insights that you can use to make your website better than your competitor’s website, even if you haven’t built it yet. This can act as or be a part of a larger ‘competitor analysis’ study. If you do have a website of your own then you’ll want to take a look at our website usability test template, which is directly comparable with this template.',
                'You’ll also want to look into affinity mapping to synthesize the data. For this particular study, affinity mapping can help you to group feedback that seems to be somewhat the same, which you can then order by size to see which concerns respondents are talking about the most.',
                'That being said, you might also want to note how important the concerns appear to be (from the way that respondents talk about them) and how easy they would be to address, as this may cause you to think differently in regards to prioritization (i.e. which concerns to address first).',
            ],
        },
    ],
};
function CompetitorPaidSearchTestBody() {
    return (React.createElement("div", null,
        React.createElement(FirstHeader, null, "What is a competitor website usability test?"),
        React.createElement("p", null, "A competitor website usability test is a test and questionnaire that asks respondents to test your competitor\u2019s website usability before collecting their feedback on it (and anything else you\u2019d like to collect feedback on, optionally)."),
        React.createElement("h3", null, "Why are competitor website usability tests used?"),
        React.createElement("p", null, "Competitor website usability tests have two main uses."),
        React.createElement("p", null, "Firstly, they\u2019re used to acquire deep insight into what respondents think of your competitor\u2019s website. If you have a website of your own already, this feedback can help you decide what needs to improve, change, or stay the same in order to stay competitive. If you don\u2019t have a website then this feedback is a treasure trove of useful information to keep in mind when designing it. Since respondents will be testing a live website rather than a prototype, the feedback will be incredibly \u2018real\u2019."),
        React.createElement("p", null, "Secondly, competitor website usability tests can also be used to compare your competitor\u2019s website to that of your own (if you have one) and track improvement over time."),
        React.createElement("h3", null, "Who uses competitor website usability tests?"),
        React.createElement("p", null, "Product designers, UX designers, and design researchers use competitor website usability tests to scout for opportunities to build websites (or improve upon websites that already exist) that are better than that of their competitors."),
        React.createElement("h3", null, "What types of data do competitor website usability tests yield?"),
        React.createElement("p", null, "Our competitor website usability test template yields mostly qualitative data consisting of written feedback, spoken feedback, and visual feedback that includes screen and face recordings. The template also includes some quantitative questions that can help you to compare your website to that of your competitors. To compare with your website, you\u2019ll want to take a look at our website usability test template."),
        React.createElement("h3", null, "What questions are in the competitor website usability test template?"),
        React.createElement("p", null, "To kick things off, the template presents respondents with a preliminary multiple-choice question designed to add some context to their answers. As is the case with all questions in all Ballark templates, you can rewrite the question, ask a different type of question, or simply remove it altogether. What you ask and how you ask it is totally up to you."),
        React.createElement("p", null, "Next, respondents will need to permit their web browser to record their face, screen, and voice before they can attempt to complete the first website test (e.g. \u201CGo to the website and add a jar of Hot Garlic to your basket\u201D)."),
        React.createElement("p", null, "After that, respondents are asked whether or not they were able to complete the task with a simple yes/no question that can be quantified. Next, after another task, a qualitative \u201CWhat did you think of \u2026?\u201D-style question. Again, the number of tasks and types of follow-up questions are completely customizable."),
        React.createElement("p", null, "And then to wrap up, respondents are asked to rate how likely they are to recommend your competitor\u2019s website to others on a scale of 0-10 (you can even use this to calculate their Net Promoter Score!). They\u2019re also asked to provide their email address, which you can use to follow up with respondents on their feedback."),
        React.createElement("h3", null, "Some of the example questions in our paid search test template:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Have you heard of the brands in these adverts?"),
            React.createElement("li", null, "Which of these adverts would you click on and why?"),
            React.createElement("li", null, "How would you improve the following advert?"),
            React.createElement("li", null, "Beyond this advert, what additional information would you need to be confident to buy?"))));
}
const FirstHeader = styled.h3 `
  margin-top: 0px !important;
`;
