import React from 'react';
import { CategorySlug } from '../types';
export const logoTestingSurvey = {
    slug: 'logo-testing-survey',
    title: 'Logo Testing Survey Template | Ballpark',
    metaDescription: 'See how memorable your logo and branding is with your audience and make the right impact with our logo test survey template.',
    heading: 'Logo testing survey template',
    subheading: 'Find the perfect logo for your new product',
    categories: [CategorySlug.BrandAndCopyTesting, CategorySlug.Marketing],
    body: React.createElement(LogoTestingSurveyBody, null),
    templateUUID: 'ut_20ec901a-c37a-49f1-87aa-0411e4e85396',
    recordLink: 'https://app.ballparkhq.com/record/20ec901a-c37a-49f1-87aa-0411e4e85396',
    accordionTitle: 'Logo testing survey FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'Can I test competitor logos alongside my own?',
            answerParagraphs: [
                'Yes! That’s a good way to understand how much your logo stands out from the competition.',
            ],
        },
    ],
};
function LogoTestingSurveyBody() {
    return (React.createElement("div", null,
        React.createElement("p", null, "One of the most challenging parts of releasing a new product is getting the branding right. Making sure your market remembers who you are and what you do is an ongoing battle and can benefit from having a memorable name and logo."),
        React.createElement("p", null, "However picking a great logo is hard, there\u2019s an endless amount of services out there that can create a logo for you, whether it\u2019s a freelance logo designer, an automated logo generator or someone on Fiverr."),
        React.createElement("p", null, "How do you make sure your logo makes an impact? By using this template to test it with your potential users of course!"),
        React.createElement("h3", null, "5 great ways to use the logo preference test template"),
        React.createElement("ul", null,
            React.createElement("li", null, "When you are undertaking a rebrand for your product"),
            React.createElement("li", null, "When you are developing a new product or feature and need to find a name or brand to kick things off"),
            React.createElement("li", null, "Quickly discover the best variation of your logo to use with your audience"),
            React.createElement("li", null, "Find out whether your logo evokes an emotional response"),
            React.createElement("li", null, "Discover whether your logo fits with your brand name"))));
}
