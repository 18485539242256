import { __rest } from "tslib";
// Ok, I apologize in advance, but I needed to refactor the toolbar without
// touching the existing one because it's already used. Once this is done
// and merged, I will swap out the responses table toolbar with this.
import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { tv } from 'tailwind-variants';
import { CloseIcon } from '@marvelapp/ballpark-icons';
import theme from '@marvelapp/ballpark-theme';
import { pluralize } from '@marvelapp/core';
import { BtwButton } from './BtwButton';
import { BtwText } from './BtwText';
import { BtwTooltip } from './BtwTooltip';
import { Stack } from './Stack';
import { cn } from './utils';
const toolbarPrimitive = tv({
    slots: {
        container: [
            // Base styles for the container
            // Positioning
            'absolute',
            'inset-x-0',
            'data-[open=true]:bottom-2',
            'z-[400]',
        ],
        toolbar: [
            // Base styles for the toolbar
            'bg-white',
            'ring-1',
            'ring-gray-600/20',
            'shadow-xl',
            'rounded-lg',
            'w-auto',
            'h-10',
            'pl-4',
            'pr-2',
        ],
        button: [
        // Base styles for buttons
        ],
        separator: [
            // Base styles for separators
            'w-px',
            'h-5',
            'bg-gray-600/10',
        ],
    },
});
const { container, toolbar, separator } = toolbarPrimitive();
function Container(props) {
    const { testId, className, children, isOpen, selectedItemCount, selectedItemNoun, onClearSelection } = props, rest = __rest(props, ["testId", "className", "children", "isOpen", "selectedItemCount", "selectedItemNoun", "onClearSelection"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(AnimatePresence, null, selectedItemCount > 0 && (React.createElement(motion.div, { key: "tableToolbar", initial: { opacity: 0, y: 8, scale: 0.9 }, animate: { opacity: 1, y: 0, scale: 1 }, exit: {
                opacity: 0,
                y: 8,
                scale: 0.9,
                transition: { duration: 0.2 },
            }, className: "absolute inset-x-0 bottom-0 z-[400] w-full", transition: {
                ease: theme.easings.smooth.array,
                duration: 0.2,
            } },
            React.createElement(ToolbarPrimitive.Root, Object.assign({ asChild: true }, rest),
                React.createElement(Stack, { "data-open": isOpen, className: cn(container()), direction: "row", justify: "center" },
                    React.createElement(Stack, { direction: "row", align: "center", className: cn(toolbar({ className })) },
                        React.createElement(Stack, { direction: "row", justify: "between", width: "full", align: "center", gap: "4" },
                            React.createElement(Stack, { direction: "row", align: "center" },
                                React.createElement(BtwText, { size: "13", weight: "medium", variant: "primary" },
                                    selectedItemCount,
                                    ' ',
                                    selectedItemNoun === 'people'
                                        ? selectedItemNoun
                                        : pluralize(selectedItemCount, selectedItemNoun),
                                    ' ',
                                    "selected")),
                            React.createElement(Separator, null),
                            React.createElement(Stack, { direction: "row", align: "center", gap: "2" },
                                children,
                                React.createElement(Button, null,
                                    React.createElement(BtwTooltip, { content: "Clear selection" },
                                        React.createElement(BtwButton, { variant: "ghost", size: "sm", standaloneIcon: React.createElement(CloseIcon, null), onClick: onClearSelection })))))))))))));
}
function Button(props) {
    if ('title' in props) {
        const { title = 'Action', onClick } = props;
        return (React.createElement(ToolbarPrimitive.Button, { asChild: true },
            React.createElement(BtwButton, { variant: "secondary", size: "sm", onClick: onClick }, title)));
    }
    const { children } = props;
    return React.createElement(ToolbarPrimitive.Button, { asChild: true }, children);
}
function Separator(props) {
    const { className } = props;
    return (React.createElement(ToolbarPrimitive.Separator, { className: cn(separator({ className })) }));
}
export const TableToolbarV2 = {
    Container,
    Button,
    Separator,
};
