import React from 'react';
import { Stack } from '../Stack';
const OrderSummary = function OrderSummaryModal({ asChild, children, }) {
    return (React.createElement(Stack, { asChild: asChild, className: "h-full divide-y divide-gray-600/10 sm:divide-x sm:divide-y-0", direction: { initial: 'col', sm: 'row' } }, children));
};
const CreditSummary = function CreditSummary({ children, }) {
    return (React.createElement(Stack, { width: "full", className: "flex-1 sm:h-full sm:max-w-[340px]" },
        React.createElement(Stack, { className: "p-6 sm:h-full", gap: { initial: '4', sm: '0' }, justify: "between", width: "full" }, children)));
};
const CreditSummaryTop = function CreditSummaryTop({ children, }) {
    return (React.createElement(Stack, { gap: "6", width: "full" }, children));
};
const CreditSummaryBottom = function CreditSummaryTop({ children, }) {
    return React.createElement(Stack, { gap: "4" }, children);
};
const OrderSummaryModal = {
    Root: OrderSummary,
    CreditSummary,
    CreditSummaryTop,
    CreditSummaryBottom,
};
export { OrderSummaryModal };
