import { __rest } from "tslib";
import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import React from 'react';
import { tv } from 'tailwind-variants';
import { Stack } from './Stack';
import { cn } from './utils';
const toolbarPrimitive = tv({
    slots: {
        container: [
            // Base styles for the container
            // Positioning
            'absolute',
            'left-1/2',
            '-translate-x-1/2',
            'data-[open=true]:bottom-2',
            'z-[400]',
        ],
        toolbar: [
            // Base styles for the toolbar
            'bg-white',
            'ring-1',
            'ring-gray-600/20',
            'shadow-xl',
            'rounded-xl',
            'w-[520px]',
            'p-2',
        ],
        button: [
        // Base styles for buttons
        ],
        separator: [
            //
            'w-px',
            'h-5',
            'bg-gray-600/10',
        ],
    },
});
const { container, toolbar, separator } = toolbarPrimitive();
function Container(props) {
    const { testId, className, children, isOpen } = props, rest = __rest(props, ["testId", "className", "children", "isOpen"]);
    return (React.createElement(ToolbarPrimitive.Root, Object.assign({ asChild: true }, rest),
        React.createElement(Stack, { "data-open": isOpen, className: cn(container()), direction: "row", justify: "center" },
            React.createElement(Stack, { direction: "row", align: "center", className: cn(toolbar({ className })) }, children))));
}
function Button(props) {
    const { children } = props;
    return React.createElement(ToolbarPrimitive.Button, { asChild: true }, children);
}
function Separator(props) {
    const { className } = props;
    return (React.createElement(ToolbarPrimitive.Separator, { className: cn(separator({ className })) }));
}
export const TableToolbar = {
    Container,
    Button,
    Separator,
};
