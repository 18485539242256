import { CATEGORIES, TEMPLATES } from './constants';
export function getTemplateCategories(template) {
    return template.categories.map((templateCategory) => {
        return CATEGORIES.find((category) => category.slug === templateCategory);
    });
}
export function getCategoryCount(category) {
    const templatesInCategory = TEMPLATES.filter((template) => {
        return getTemplateCategories(template).find((c) => (c === null || c === void 0 ? void 0 : c.slug) === category.slug);
    });
    return templatesInCategory.length;
}
export function getTemplateBySlug(slug) {
    return TEMPLATES.find((t) => t.slug === slug);
}
export function getCategoryBySlug(slug) {
    return CATEGORIES.find((c) => c.slug === slug);
}
