import React from 'react';
import styled from '@marvelapp/styled';
export const NestedList = ({ children }) => {
    return React.createElement(NestedListElement, null, children);
};
const NestedListElement = styled.ul `
  ul {
    margin-top: 16px !important;
    li {
      list-style-type: circle;
    }
  }
`;
