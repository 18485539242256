import React from 'react';
import { Link } from '../Link';
import { CategorySlug } from '../types';
export const figmaCheckoutFlowTest = {
    slug: 'figma-checkout-flow-test',
    title: 'Figma Checkout Flow Template | Ballpark',
    metaDescription: 'See how the usability of your Figma check out flow prototype performs with this test template.',
    heading: 'Figma prototype checkout flow test template',
    subheading: 'Find out whether your designs convert customers',
    categories: [CategorySlug.PrototypeTesting, CategorySlug.DesignAndProduct],
    body: React.createElement(FigmaCheckoutFlowTestBody, null),
    templateUUID: 'ut_c1d870c9-5310-47b8-a609-f5a502b206c7',
    recordLink: 'https://app.ballparkhq.com/record/c1d870c9-5310-47b8-a609-f5a502b206c7',
    accordionTitle: 'Figma checkout flow test template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'Can I add multiple Figma prototypes to the same test?',
            answerParagraphs: [
                'Yes! You can test several variations within the same test, simply add more steps!',
            ],
        },
        {
            id: 2,
            question: 'When should you test a checkout flow?',
            answerParagraphs: [
                'Our Figma prototype checkout flow template is a great way to validate designs prior to starting to code. Simply add your Figma prototype link and share it with your potential customers to gather feedback.',
            ],
        },
        {
            id: 3,
            question: 'How often should you test a checkout flow?',
            answerParagraphs: [
                'Our top tip would be “test, test and test again!”',
                'Testing your ecommerce site or app pre-launch is a vital step and one that shouldn’t be avoided. You need to be confident that your cart and checkout flow sections are running seamlessly before going live. ',
                'But the testing shouldn’t stop there! Even once you’re up and running and established, we’d suggest testing your checkout design process every week in Ballpark. It should only take a few minutes and that way you’ll start to get a feel for what the ecommerce checkout best practices really are. ',
            ],
        },
        {
            id: 4,
            question: 'Where can I find users to test my Figma prototype checkout flow?',
            answerParagraphs: [
                'You could potentially contact previous or existing customers using a list of the email addresses provided at checkout. Or why not take a look at the 92,000 active global participants who make up the Ballpark recruitment panel and receive a wealth of insights within approximately 2 hours?',
            ],
        },
        {
            id: 5,
            question: 'How else does Ballpark help you test your Figma prototypes?',
            answerParagraphs: [React.createElement(AnswerItem, null)],
        },
    ],
};
function FigmaCheckoutFlowTestBody() {
    return (React.createElement("div", null,
        React.createElement("h3", null, "What is a checkout flow?"),
        React.createElement("p", null, "The checkout flow is arguably one of the most important aspects of any ecommerce website or app and the need to create a consistent checkout experience is higher than ever."),
        React.createElement("p", null, "A checkout flow is a series of steps that a potential customer needs to follow in order to pay for the product they (hopefully) want to buy on your ecommerce site."),
        React.createElement("p", null, "Pretty much every checkout flow starts with clicking on an eye-catching \u201Cadd to cart\u201D button and ends post-payment with a \u201Cthank you\u201D or \u201Corder confirmation\u201D page."),
        React.createElement("h3", null, "Why is it important to test your checkout flow?"),
        React.createElement("p", null, "Out of all the metrics you\u2019ll be tracking on your site, to increase conversion rate is the ultimate goal. If you can boost your checkout completion rate by even five percent, you\u2019ve made a significant difference to your investment return on."),
        React.createElement("p", null, "So if a potential buyer experiences a poor checkout flow, this can subsequently result in an abandoned online cart which as a business owner, is the last thing you want right?"),
        React.createElement("p", null, "By using the Figma prototype checkout flow test template in Ballpark you can gain lots of insights into whether the checkout design is too complicated, too long or ineffective. Then by using the insights gathered you can essentially understand what needs to be improved to make the user experience hassle-free."),
        React.createElement("h3", null, "What data does the Figma prototype checkout flow test provide?"),
        React.createElement("p", null, "Ballpark tracks how many participants reach the payment confirmation page in your Figma prototype checkout flow. Plus we can record the participant\u2019s audio, webcam and screen so you can see exactly if and/or where they ran into any problems by reviewing the recordings. Heatmaps will also show you which areas users clicked (or misclicked) and focused on for each screen."),
        React.createElement("h3", null, "What questions are in the Figma prototype checkout flow test template?"),
        React.createElement("p", null, "The Ballpark prototype checkout flow template begins with a detailed instruction step asking participants to add a particular item to the shopping cart and proceed through the checkout flow to complete the purchase."),
        React.createElement("p", null,
            React.createElement("i", null,
                React.createElement("b", null, "Please read:"))),
        React.createElement("p", null, "In order for your Figma prototype checkout flow test to be successful and convert highly you need to ensure you\u2019ve optimised the Figma prototype first."),
        React.createElement("p", null,
            "Not sure where to start? No worries. We\u2019ve put together a",
            ' ',
            React.createElement(Link, { href: "https://help.ballparkhq.com/hc/en-us/articles/8430019855889-Optimising-your-Figma-and-Marvel-prototypes-for-testing" }, "guide"),
            ' ',
            "for both Marvel and Figma prototypes to ensure your participants have a great, speedy experience when testing and generate the answers you need for your checkout design test."),
        React.createElement("p", null, "The Figma prototype test template beings with asking respondents to rate their experience with the checkout flow on a scale of 1 (not user friendly) to 10 (very user friendly). The answers from this numerical rating scale step allow you to compare customer responses in a quantitative way that depicts their satisfaction with the checkout flow. An average of the results would help determine if your checkout design is effective, can be compared to your ecommerce competitors, and be tracked over time as you make your improvements."),
        React.createElement("p", null, "Multiple choice questions are a key part of any Ballpark project creation and have proven themselves to be very popular as they\u2019re easy for participants to answer, and easy for researchers, like you, to create and analyse. They\u2019re also a more reliable means of assessment than a simple yes/no or true/false step as they\u2019re less susceptible to guessing. Therefore, we made sure to ask how intuitive users found the checkout flow of the Figma prototype to be as the penultimate step of the checkout flow template."),
        React.createElement("p", null, "To wrap up the Figma prototype checkout flow template we ask the user to provide extra context surrounding what they believe can be done to improve the checkout flow process. Maybe the participant wants to expand on a previous answer? Maybe they didn\u2019t feel like any of the questions really captured their view? This is the step to find any more of those special nuggets of information. You could even set audio recording on this step and ask that they speak out loud so their answer feels human and less restricted."),
        React.createElement("h3", null, "Et voila!"),
        React.createElement("p", null, "As with all the templates available in Ballpark, they are customizable so you can reorder or add as many additional questions as you like.")));
}
function AnswerItem() {
    return (React.createElement("div", { className: "mt-6" },
        React.createElement("p", null, "Ballpark can provide insights into your participant\u2019s interaction with the Figma prototype by recording their audio, video and screen recording. Having a visual record shows you exactly what happened during the test with immediate playback and enables for more detailed remote analysis after the test. You can compare individual responses, identify any areas where participants hesitated or became stuck, and spot areas for improvement all to ensure you create a seamless user experience."),
        React.createElement("p", null,
            "You can also use Ballpark to help understand the usability of your Figma prototype components. And we\u2019ve got another template especially for this use case waiting for you",
            ' ',
            React.createElement(Link, { href: "https://ballparkhq.com/template/figma-interactive-component-test", textDecoration: "none" }, "here."),
            ' ')));
}
