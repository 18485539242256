import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import { ArchiveIcon, ArrowLeftIcon, BagIcon, BillIcon, CloseIcon, CreditCardIcon, DashboardFastIcon, DotsIcon, FolderIcon, FolderUserIcon, HamburgerIcon, LayoutGridIcon, LogoutIcon, MapIcon, MegaphoneIcon, PeopleBehindIcon, PeopleCircleIcon, RescueRingIcon, SettingsIcon, StoreIcon, } from '@marvelapp/ballpark-icons';
import theme from '@marvelapp/ballpark-theme';
import { CATEGORIES, CATEGORYGROUPS, TEMPLATES, } from '@marvelapp/templates/src/constants';
import { getCategoryCount } from '@marvelapp/templates/src/utils';
import { Badge } from './Badge';
import { BtwAvatar } from './BtwAvatar';
import { BtwButton, baseButtonStyles, ghostButtonStyles } from './BtwButton';
import { BtwDropdown } from './BtwDropdown';
import { BtwHeading } from './BtwHeading';
import { BtwText } from './BtwText';
import { BtwTooltip } from './BtwTooltip';
import { SidebarBanner } from './SidebarBanner';
import { Stack } from './Stack';
import { cn } from './utils';
const sidebarClasses = tv({
    slots: {
        sidebarBackground: [
            // Small screen stylign
            'absolute',
            'data-[collapsed=false]:w-full',
            'data-[collapsed=false]:h-full',
            'block',
            'z-50',
            // Big screen styling
            'md:hidden',
        ],
        sidebarPanel: [
            // Base styling
            'h-full',
            'shrink-0',
            'overflow-hidden',
            'bg-gray-50',
            'ring-1',
            'ring-gray-600/10',
            'transition-all',
            'ease-smooth',
            'duration-300',
            // Small screen styling
            'absolute',
            'w-full',
            'max-w-80',
            '-left-80',
            'data-[collapsed=false]:left-0',
            'z-50',
            // Big screen styling
            'md:relative',
            'md:w-64',
            'md:max-w-64',
            'md:left-0',
        ],
    },
});
const { sidebarPanel, sidebarBackground } = sidebarClasses();
var SidebarPanel;
(function (SidebarPanel) {
    SidebarPanel["Main"] = "main";
    SidebarPanel["Settings"] = "settings";
    SidebarPanel["Templates"] = "Templates";
})(SidebarPanel || (SidebarPanel = {}));
var SidebarNavItem;
(function (SidebarNavItem) {
    // Main sidebar menu
    SidebarNavItem["Main_AllProjects"] = "All projects";
    SidebarNavItem["Main_MyProjects"] = "My projects";
    SidebarNavItem["Main_Archive"] = "Archive";
    SidebarNavItem["Main_People"] = "People";
    SidebarNavItem["Main_Templates"] = "Templates";
    SidebarNavItem["Main_Help"] = "Help";
    SidebarNavItem["Main_Roadmap"] = "Roadmap";
    // Settings menu
    SidebarNavItem["Settings_Personal"] = "Personal";
    SidebarNavItem["Settings_Workspace"] = "Workspace";
    SidebarNavItem["Settings_PlanUsage"] = "Plan & Usage";
    SidebarNavItem["Settings_Billing"] = "Billing";
    SidebarNavItem["Settings_RecruitmentOrders"] = "Recruitment orders";
    SidebarNavItem["Settings_ConnectedApps"] = "Connected apps";
    SidebarNavItem["Templates_All"] = "All";
})(SidebarNavItem || (SidebarNavItem = {}));
function AppSidebar(props) {
    const { initialMenuItem, initialActivePanel } = props;
    const [activePanel, setActivePanel] = useState(initialActivePanel || SidebarPanel.Main);
    const [activeMenuItem, setActiveMenuItem] = useState(initialMenuItem || SidebarNavItem.Main_AllProjects);
    // Setting the sidebar as collapsed by default, so that when the viewport
    // gets small, the sidebar is closed by default. This prop only controls the sidebar
    // in smaller viewports.
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);
    const sidebarRef = useRef(null);
    const sidebarBackgroundRef = useRef(null);
    // Closing the sidebar and making sure we reset the active panel
    // in case the user closed it while the settings was open.
    // This logic should be further improved to only apply IF the current
    // page is not a settings page.
    const toggleSidebar = () => {
        setSidebarCollapsed(!isSidebarCollapsed);
        if (activePanel !== initialActivePanel) {
            setActivePanel(initialActivePanel);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(BtwTooltip, { content: "Open menu" },
            React.createElement(BtwButton, { size: "sm", standaloneIcon: React.createElement(HamburgerIcon, null), className: "absolute left-8 top-3 z-20 flex md:hidden", onClick: toggleSidebar })),
        React.createElement("div", { className: cn(sidebarBackground()), "data-collapsed": isSidebarCollapsed, ref: sidebarBackgroundRef, onClick: toggleSidebar, role: "none" }),
        React.createElement(Stack, { direction: "row", className: cn(sidebarPanel()), ref: sidebarRef, "data-collapsed": isSidebarCollapsed },
            React.createElement(AnimatePresence, { initial: false },
                activePanel === SidebarPanel.Main && (React.createElement(motion.div, { key: "sidebar-main", className: "absolute h-full w-full", initial: { x: '-100%' }, animate: { x: 0 }, exit: {
                        x: '-100%',
                    }, transition: { ease: theme.easings.smooth.array, duration: 0.2 } },
                    React.createElement(MainSidebarPanel, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed }))),
                activePanel === SidebarPanel.Settings && (React.createElement(motion.div, { key: "sidebar-settings", className: "absolute h-full w-full", initial: { x: '100%' }, animate: { x: 0 }, exit: {
                        x: '100%',
                    }, transition: { ease: theme.easings.smooth.array, duration: 0.2 } },
                    React.createElement(SettingsSidebarPanel, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed }))),
                activePanel === SidebarPanel.Templates && (React.createElement(motion.div, { key: "sidebar-settings", className: "absolute h-full w-full", initial: { x: '100%' }, animate: { x: 0 }, exit: {
                        x: '100%',
                    }, transition: { ease: theme.easings.smooth.array, duration: 0.2 } },
                    React.createElement(TemplatesSidebarPanel, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed })))))));
}
function MainSidebarPanel(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel, setSidebarCollapsed, isSidebarCollapsed, } = props;
    return (React.createElement(Stack, { className: "h-full min-w-64" },
        React.createElement(UserSection, { setActivePanel: setActivePanel, setSidebarCollapsed: setSidebarCollapsed, isSidebarCollapsed: isSidebarCollapsed }),
        React.createElement(Stack, { width: "full" },
            React.createElement(Stack, { className: "px-3 pt-3", width: "full" },
                React.createElement(BtwButton, { variant: "secondary", width: "full" }, "Create new project"))),
        React.createElement(Stack, { width: "full", className: "h-full divide-y divide-gray-600/10" },
            React.createElement(SidebarMenu, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel }))));
}
function SettingsSidebarPanel(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel, isSidebarCollapsed, setSidebarCollapsed, } = props;
    return (React.createElement(SecondarySidebarPanel, { title: "Settings", setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed }, settingsMenu.map((item) => {
        const { id, title, icon, url } = item;
        return (React.createElement(MenuItem, { isActive: title === activeMenuItem, key: id, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }));
    })));
}
function TemplatesSidebarPanel(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel, isSidebarCollapsed, setSidebarCollapsed, } = props;
    // Dunno if this is the right way of doing this, but it feels ok
    const templateCategoryGroups = CATEGORYGROUPS;
    const templateCategories = CATEGORIES;
    const templates = TEMPLATES;
    return (React.createElement(SecondarySidebarPanel, { title: "Templates", setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed },
        React.createElement(Stack, { className: "divide-y-gray-600/10 divide-y", width: "full", gap: "3" }, templateCategoryGroups.map((group) => {
            const categories = templateCategories.filter((sidebarCategory) => sidebarCategory.group === group.slug);
            const templatesCount = templates.length;
            return (React.createElement(Stack, { gap: "3", key: group.slug, width: "full", className: "[&:not(:first-child)]:pt-3" },
                React.createElement(BtwHeading, { variant: "secondary", size: "xxs", className: "px-3 pt-2 uppercase" }, group.name),
                React.createElement(Stack, { gap: "0.5", width: "full" },
                    group.slug === 'featured' && (React.createElement(MenuItem, { key: "All", isActive: SidebarNavItem.Templates_All === activeMenuItem, title: SidebarNavItem.Templates_All, url: "/", onClick: () => setActiveMenuItem(SidebarNavItem.Templates_All), badgeCount: templatesCount })),
                    categories.map((category) => {
                        if (getCategoryCount(category) > 0) {
                            return (React.createElement(MenuItem, { isActive: category.name === activeMenuItem, key: category.slug, title: category.name, url: `#`, 
                                // Not really sure yet how to get this working, but I need to get deploy previews up
                                // so I'm just leaving this for now.
                                // Getting it to accept the categoryNameKey is just impossible.
                                onClick: () => setActiveMenuItem(SidebarNavItem.Main_AllProjects), badgeCount: getCategoryCount(category) }));
                        }
                        return null;
                    }))));
        }))));
}
function SecondarySidebarPanel(props) {
    const { setActivePanel, isSidebarCollapsed, setSidebarCollapsed, title, children, } = props;
    return (React.createElement(Stack, { className: "h-full min-w-64" },
        React.createElement(Stack, { direction: "row", align: "center", width: "full", gap: "2", className: "h-[52px] border-b border-gray-600/10 px-5", justify: "between" },
            React.createElement(Stack, { direction: "row", align: "center", gap: "2" },
                React.createElement(BtwTooltip, { content: "Back to main menu" },
                    React.createElement(BtwButton, { variant: "ghost", size: "sm", standaloneIcon: React.createElement(ArrowLeftIcon, null), onClick: () => {
                            setActivePanel(SidebarPanel.Main);
                        } })),
                React.createElement(BtwText, { size: "sm", variant: "primary", weight: "medium" }, title)),
            React.createElement(Stack, { className: "block md:hidden" },
                React.createElement(BtwTooltip, { content: "Close menu" },
                    React.createElement(BtwButton, { standaloneIcon: React.createElement(CloseIcon, null), variant: "ghost", size: "sm", onClick: () => setSidebarCollapsed(!isSidebarCollapsed), className: "flex md:hidden" })))),
        React.createElement(Stack, { width: "full", className: "p-3", gap: "0.5" }, children)));
}
function UserSection(props) {
    const { setActivePanel, setSidebarCollapsed, isSidebarCollapsed } = props;
    return (React.createElement(Stack, { direction: "row", gap: "3", align: "center", width: "full", className: "h-[52px] shrink-0 border-b border-gray-600/10 px-3" },
        React.createElement(Stack, { className: "relative" },
            React.createElement(BtwAvatar, { name: user.name, size: "lg", src: user.avatarUrl }),
            React.createElement("img", { src: user.companyImageUrl, className: "absolute -bottom-px -right-1 size-4 rounded-sm ring-2 ring-gray-50", alt: user.companyName })),
        React.createElement(Stack, { width: "full" },
            React.createElement(BtwText, { variant: "primary", size: "13", weight: "medium", className: "leading-5" }, user.name),
            React.createElement(BtwText, { size: "xs", weight: "medium", variant: "secondary", className: "-mt-0.5" }, user.companyName)),
        React.createElement(Stack, null,
            React.createElement(BtwDropdown.Root, null,
                React.createElement(Stack, { direction: "row", gap: "1" },
                    React.createElement(BtwTooltip.Root, null,
                        React.createElement(BtwTooltip.Trigger, null,
                            React.createElement(BtwDropdown.Trigger, { "data-testid": "sidebar-more-actions", size: "sm", standaloneIcon: React.createElement(DotsIcon, null), variant: "ghost" })),
                        React.createElement(BtwTooltip.Portal, null,
                            React.createElement(BtwTooltip.Content, null, "More actions"))),
                    React.createElement(BtwTooltip, { content: "Close menu" },
                        React.createElement(BtwButton, { standaloneIcon: React.createElement(CloseIcon, null), variant: "ghost", size: "sm", onClick: () => setSidebarCollapsed(!isSidebarCollapsed), className: "flex md:hidden" }))),
                React.createElement(BtwDropdown.Content, { className: "w-48" },
                    React.createElement(BtwDropdown.Stack, null,
                        React.createElement(BtwDropdown.Item, { leadingIcon: React.createElement(SettingsIcon, null), onSelect: () => {
                                setActivePanel(SidebarPanel.Settings);
                            } }, "Settings"),
                        React.createElement(BtwDropdown.Item, { leadingIcon: React.createElement(MegaphoneIcon, null) }, "Release notes")),
                    React.createElement(BtwDropdown.Separator, null),
                    React.createElement(BtwDropdown.Stack, null,
                        React.createElement(BtwDropdown.Item, { leadingIcon: React.createElement(LogoutIcon, null) }, "Log out")))))));
}
function SidebarMenu(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel } = props;
    return (React.createElement(Stack, { width: "full", justify: "between", className: "h-full" },
        React.createElement(Stack, { width: "full", className: "divide-y divide-gray-600/10" },
            React.createElement(Stack, { width: "full", className: "p-3", gap: "0.5" }, sidebarProjectsMenu.map((item) => {
                const { id, title, icon, url } = item;
                return (React.createElement(MenuItem, { isActive: title === activeMenuItem, key: id, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }));
            })),
            React.createElement(Stack, { width: "full", className: " p-3", gap: "0.5" }, otherItemsMenu.map((item) => {
                const { id, title, icon, url } = item;
                return (React.createElement(MenuItem, { isActive: title === activeMenuItem, key: id, title: title, icon: icon, url: url, onClick: () => {
                        // I am sure there are is a right way of doing this, but for the sake of an example:
                        if (title === SidebarNavItem.Main_Templates) {
                            setActivePanel(SidebarPanel.Templates);
                        }
                        setActiveMenuItem(title);
                    } }));
            }))),
        React.createElement(Stack, { width: "full", className: "divide-y divide-gray-600/10" },
            React.createElement(Stack, { className: "p-3" },
                React.createElement(SidebarBanner, { title: "9 days left in your trial", description: "Upgrade early or book a personalised demo for your team.", progressValue: 9, progressMax: 14, actions: React.createElement(BtwButton, { size: "sm", variant: "primary" }, "Upgrade now") })),
            React.createElement(Stack, { width: "full", className: " p-3", gap: "0.5" }, helpMenu.map((item) => {
                const { id, title, icon, url } = item;
                return (React.createElement(MenuItem, { isActive: title === activeMenuItem, key: id, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }));
            })))));
}
// I opted to use custom components here instead of buttons, because I had to override
// too many things to make the buttons fit the sidebar nav.
function MenuItem(props) {
    const { icon, title, key, url, isActive, onClick, badgeCount } = props;
    const menuItemClasses = [
        // Base styles
        'h-9',
        'w-full',
        'gap-2',
        'justify-start',
        'px-3',
        'items-center',
        'rounded-md',
        'text-gray-700',
        // Similar to Yav's findingings (BtwButton.tsx:223), the text-13 class breaks styles
        // hence the arbitrary value
        'text-[13px]',
        'font-medium',
        // Hover styles
        'hover:bg-gray-200/50',
        'hover:ring-gray-200/50',
        // Active styles
        'data-[state=active]:text-gray-900',
        'data-[state=active]:bg-gray-200/50',
        'data-[state=active]:ring-gray-200/50',
    ];
    return (React.createElement("a", { href: url, key: key, className: cn(...baseButtonStyles, ...ghostButtonStyles, menuItemClasses), onClick: onClick, "data-state": isActive ? 'active' : '' },
        React.createElement("span", { className: "inline-flex w-full gap-2" },
            icon && React.createElement("span", { className: "[&>svg]:size-5" }, icon),
            React.createElement("span", null, title)),
        badgeCount && (React.createElement(Badge, { size: "sm", className: "justify-self-end" }, badgeCount))));
}
export { AppSidebar, SidebarPanel, SidebarNavItem };
const sidebarProjectsMenu = [
    {
        id: 0,
        title: SidebarNavItem.Main_AllProjects,
        icon: React.createElement(FolderIcon, null),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Main_MyProjects,
        icon: React.createElement(FolderUserIcon, null),
        url: '#',
    },
    {
        id: 2,
        title: SidebarNavItem.Main_Archive,
        icon: React.createElement(ArchiveIcon, null),
        url: '#',
    },
];
const otherItemsMenu = [
    {
        id: 0,
        title: SidebarNavItem.Main_People,
        icon: React.createElement(PeopleBehindIcon, null),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Main_Templates,
        icon: React.createElement(StoreIcon, null),
        url: '#',
    },
];
const helpMenu = [
    {
        id: 0,
        title: SidebarNavItem.Main_Help,
        icon: React.createElement(RescueRingIcon, null),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Main_Roadmap,
        icon: React.createElement(MapIcon, null),
        url: '#',
    },
];
const settingsMenu = [
    {
        id: 0,
        title: SidebarNavItem.Settings_Personal,
        icon: React.createElement(PeopleCircleIcon, null),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Settings_Workspace,
        icon: React.createElement(BagIcon, null),
        url: '#',
    },
    {
        id: 2,
        title: SidebarNavItem.Settings_PlanUsage,
        icon: React.createElement(DashboardFastIcon, null),
        url: '#',
    },
    {
        id: 3,
        title: SidebarNavItem.Settings_Billing,
        icon: React.createElement(CreditCardIcon, null),
        url: '#',
    },
    {
        id: 4,
        title: SidebarNavItem.Settings_RecruitmentOrders,
        icon: React.createElement(BillIcon, null),
        url: '#',
    },
    {
        id: 5,
        title: SidebarNavItem.Settings_ConnectedApps,
        icon: React.createElement(LayoutGridIcon, null),
        url: '#',
    },
];
const user = {
    name: 'Alfred Bottleneck',
    avatarUrl: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?&w=128&h=128&dpr=2&q=80',
    companyName: 'Acme Inc',
    companyImageUrl: 'https://images.unsplash.com/photo-1574169208507-84376144848b?&w=128&h=128&dpr=2&q=80',
};
