import { createColumnHelper, getCoreRowModel, useReactTable, } from '@tanstack/react-table';
import React, { useState } from 'react';
import { RefreshIcon } from '@marvelapp/ballpark-icons';
import { BtwAvatar } from '../BtwAvatar';
import { BtwButton } from '../BtwButton';
import { BtwDataTable } from '../BtwDataTable';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { Progress } from '../Progress';
import { ScrollArea } from '../ScrollArea';
import { AppSidebar, SidebarNavItem, SidebarPanel } from '../Sidebar';
import { Stack } from '../Stack';
import { cn } from '../utils';
import { SettingsHeader } from './SettingsHeader';
function RecruitmentOrdersSettingsPage() {
    const [data] = useState(() => [...CreditsHistory]);
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        initialState: {
            sorting: [
                {
                    id: 'date',
                    desc: false,
                },
            ],
        },
    });
    return (React.createElement(Stack, { width: "full", className: "h-full", direction: "row" },
        React.createElement(AppSidebar, { initialMenuItem: SidebarNavItem.Settings_RecruitmentOrders, initialActivePanel: SidebarPanel.Settings }),
        React.createElement(Stack, { className: "h-full overflow-y-hidden", width: "full" },
            React.createElement(SettingsHeader.Container, null,
                React.createElement(SettingsHeader.Title, null, "Recruitment orders"),
                React.createElement(SettingsHeader.Actions, null,
                    React.createElement(BtwButton, { size: "sm" }, "Buy more credits"))),
            React.createElement(Stack, { width: "full", gap: "8", className: "mx-auto h-full max-w-6xl flex-1 overflow-y-scroll" },
                React.createElement(Stack, { width: "full", gap: "8", className: "h-full p-8" },
                    React.createElement("div", { className: cn(creditsStatsContainerClasses) },
                        React.createElement(Stack, { className: "p-6", gap: "3", width: "full", justify: "between" },
                            React.createElement(BtwText, { size: "13", weight: "medium" }, "Current balance"),
                            React.createElement(BtwHeading, { size: "3xl", weight: "medium" }, formatNumber(CreditsStats.currentBalance))),
                        React.createElement(Stack, { className: "p-6", gap: "3", width: "full", justify: "between" },
                            React.createElement(BtwText, { size: "13", weight: "medium" }, "Minutes used (last 30 days)"),
                            React.createElement(BtwHeading, { size: "3xl", weight: "medium" }, formatNumber(CreditsStats.minutesUsedLast30Days))),
                        React.createElement(Stack, { className: "p-6", gap: "3", width: "full", justify: "between" },
                            React.createElement(BtwText, { size: "13", weight: "medium" }, "Total minutes used"),
                            React.createElement(BtwHeading, { size: "3xl", weight: "medium" }, formatNumber(CreditsStats.totalMinutesUsed))),
                        React.createElement(Stack, { className: "p-6", gap: "3", width: "full", justify: "between" },
                            React.createElement(BtwText, { size: "13", weight: "medium" }, "Total participants recruited"),
                            React.createElement(BtwHeading, { size: "3xl", weight: "medium" }, formatNumber(CreditsStats.totalParticipants)))),
                    React.createElement(Stack, { className: cn(tableClassNames), width: "full" },
                        React.createElement(ScrollArea, null,
                            React.createElement(BtwDataTable, { table: table, withStickyHeader: true }))))))));
}
// Setting up the table
const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor('date', {
        size: 140,
        header: 'Date',
        cell: (data) => {
            const timestamp = data.getValue();
            const date = new Date(timestamp);
            const formattedDate = date.toLocaleDateString('en-GB', {
                dateStyle: 'medium',
            });
            return React.createElement(BtwText, null, formattedDate);
        },
        enableSorting: false,
        id: 'date',
        meta: {
            sorting: {
                name: 'Date',
                id: 'date',
                type: 'date',
            },
        },
    }),
    columnHelper.accessor('name', {
        size: 240,
        header: 'Name',
        cell: (data) => {
            const name = data.getValue();
            const rowData = data.row.original;
            return (React.createElement(Stack, { direction: "row", gap: "1", align: "center" },
                rowData.type === CreditTransactionType.refund && (React.createElement(RefreshIcon, { className: "size-5" })),
                React.createElement(BtwText, { weight: "medium", variant: "primary", truncate: true }, name)));
        },
        enableSorting: false,
    }),
    columnHelper.accessor('fulfilled', {
        size: 150,
        header: 'Status',
        cell: (data) => {
            const responsesFulfilled = data.getValue();
            const responsesOrdered = data.row.original.responses;
            if (responsesFulfilled) {
                const percentage = (responsesFulfilled / responsesOrdered) * 100;
                return (React.createElement(Stack, { width: "full", direction: "row", align: "center", gap: "2" },
                    React.createElement(Progress.Animated, { value: percentage, className: "w-12 *:data-[state=complete]:bg-green-500" }),
                    React.createElement(BtwText, null,
                        responsesFulfilled,
                        "/",
                        responsesOrdered)));
            }
            return null;
        },
        enableSorting: false,
    }),
    columnHelper.accessor('userName', {
        header: 'User',
        cell: (data) => {
            const userName = data.getValue();
            const rowData = data.row.original;
            return (React.createElement(Stack, { direction: "row", gap: "2", align: "center" },
                React.createElement(BtwAvatar, { name: userName, src: rowData.type === CreditTransactionType.annualAllowance ||
                        rowData.type === CreditTransactionType.refund
                        ? 'https://ballparkhq.com/images/favicon.png'
                        : '', size: "sm" }),
                React.createElement(BtwText, { truncate: true }, userName)));
        },
        enableSorting: false,
    }),
    columnHelper.accessor('responses', {
        size: 100,
        header: () => {
            return (React.createElement(BtwText, { align: "right", variant: "primary" }, "Responses"));
        },
        cell: (data) => {
            const minutes = data.getValue();
            if (minutes <= 0) {
                return null;
            }
            return React.createElement(BtwText, { align: "right" }, minutes);
        },
        enableSorting: false,
    }),
    columnHelper.accessor('minutes', {
        size: 100,
        header: () => {
            return (React.createElement(BtwText, { align: "right", variant: "primary" }, "Minutes"));
        },
        cell: (data) => {
            const responses = data.getValue();
            if (responses <= 0) {
                return null;
            }
            return React.createElement(BtwText, { align: "right" }, responses);
        },
        enableSorting: false,
    }),
    columnHelper.accessor('total', {
        size: 80,
        header: () => {
            return (React.createElement(BtwText, { align: "right", variant: "primary" }, "Total"));
        },
        cell: (data) => {
            const total = data.getValue();
            const transactionType = data.row.original.type;
            if (transactionType !== CreditTransactionType.recruitmentOrder) {
                return (React.createElement(BtwText, { align: "right", variant: "green", weight: "semibold" },
                    "+",
                    total));
            }
            return (React.createElement(BtwText, { align: "right", variant: "primary", weight: "semibold" }, total));
        },
        enableSorting: false,
    }),
];
const tableClassNames = [
    'rounded-lg',
    '[&_table]:border-spacing-0',
    'border',
    'border-gray-600/10',
    'overflow-x-scroll',
    '[&_td]:border-x-0',
    '[&_td]:px-6',
    '[&_td]:py-4',
    '[&_th]:border-x-0',
    'first:[&_td]:border-x-0',
    'first:[&_th]:border-x-0',
    'first:[&_tbody>tr]:hidden',
    'last:[&_tbody>tr]:hidden',
    // A small little z-index helper for the scrollArea cause of the increased z-index on the table header
    '[&_div[data-orientation=vertical]]:z-50',
];
var CreditTransactionType;
(function (CreditTransactionType) {
    CreditTransactionType["recruitmentOrder"] = "Recruitment order";
    CreditTransactionType["annualAllowance"] = "Annual allowance";
    CreditTransactionType["refund"] = "Refund";
    CreditTransactionType["creditsPurchase"] = "Credits purchase";
})(CreditTransactionType || (CreditTransactionType = {}));
const CreditsHistory = [
    {
        date: '2024-05-01',
        name: 'Annual Business Plan allowance',
        userName: 'Ballpark',
        responses: 0,
        minutes: 0,
        total: 300,
        type: CreditTransactionType.annualAllowance,
    },
    {
        date: '2024-05-02',
        name: 'Shopify homepage copy test',
        userName: 'Murat Mutlu',
        responses: 20,
        minutes: 5,
        fulfilled: 5,
        total: 100,
        type: CreditTransactionType.recruitmentOrder,
    },
    {
        date: '2024-05-03',
        name: 'Recruitment credits top-up',
        userName: 'Carol Davis',
        responses: 0,
        minutes: 0,
        total: 200,
        type: CreditTransactionType.creditsPurchase,
    },
    {
        date: '2024-05-04',
        name: 'Website Homepage Test: Philips Healthcare',
        userName: 'David Martin',
        responses: 18,
        fulfilled: 10,
        minutes: 10,
        total: 720,
        type: CreditTransactionType.recruitmentOrder,
    },
    {
        date: '2024-05-05',
        name: 'Refund',
        userName: 'Ballpark',
        responses: 0,
        minutes: 0,
        total: 75,
        type: CreditTransactionType.refund,
    },
    {
        date: '2024-05-06',
        name: "Website Homepage Test: Sainsbury's",
        userName: 'Frank Wilson',
        responses: 10,
        fulfilled: 5,
        minutes: 5,
        total: 100,
        type: CreditTransactionType.recruitmentOrder,
    },
    {
        date: '2024-05-07',
        name: 'Recruitment credits top-up',
        userName: 'Grace Lee',
        responses: 0,
        minutes: 0,
        total: 100,
        type: CreditTransactionType.creditsPurchase,
    },
    {
        date: '2024-05-08',
        name: 'Annual Business Plan allowance',
        userName: 'Ballpark',
        responses: 0,
        minutes: 0,
        total: 600,
        type: CreditTransactionType.annualAllowance,
    },
    {
        date: '2024-05-09',
        name: 'Website Usability Test Peloton',
        userName: 'Ivy Harris',
        responses: 7,
        fulfilled: 7,
        minutes: 5,
        total: 140,
        type: CreditTransactionType.recruitmentOrder,
    },
    {
        date: '2024-05-10',
        name: 'Refund',
        userName: 'Ballpark',
        responses: 0,
        minutes: 0,
        total: 50,
        type: CreditTransactionType.refund,
    },
    {
        date: '2024-05-11',
        name: 'Marketing Study: AI in Design and Product',
        userName: 'Kathy Lewis',
        responses: 4,
        fulfilled: 4,
        minutes: 80,
        total: 80,
        type: CreditTransactionType.recruitmentOrder,
    },
    {
        date: '2024-05-12',
        name: 'Recruitment credits top-up',
        userName: 'Liam Young',
        responses: 0,
        minutes: 0,
        total: 250,
        type: CreditTransactionType.creditsPurchase,
    },
    {
        date: '2024-05-13',
        name: 'Website Homepage Test: Philips Healthcare',
        userName: 'Mia Allen',
        responses: 20,
        fulfilled: 20,
        minutes: 10,
        total: 800,
        type: CreditTransactionType.recruitmentOrder,
    },
    {
        date: '2024-05-14',
        name: 'Annual Business Plan allowance',
        userName: 'Ballpark',
        responses: 0,
        minutes: 0,
        total: 600,
        type: CreditTransactionType.annualAllowance,
    },
    {
        date: '2024-05-15',
        name: 'BT Business x Ballpark Pilot',
        userName: 'Oscar King',
        responses: 9,
        fulfilled: 5,
        minutes: 8,
        total: 72,
        type: CreditTransactionType.recruitmentOrder,
    },
    {
        date: '2024-05-16',
        name: 'Refund',
        userName: 'Ballpark',
        responses: 0,
        minutes: 0,
        total: 25,
        type: CreditTransactionType.refund,
    },
    {
        date: '2024-05-17',
        name: 'Website Usability Test Music Magpie',
        userName: 'Quinn Parker',
        responses: 15,
        fulfilled: 15,
        minutes: 10,
        total: 600,
        type: CreditTransactionType.recruitmentOrder,
    },
    {
        date: '2024-05-18',
        name: 'Recruitment credits top-up',
        userName: 'Ruby Nelson',
        responses: 0,
        minutes: 0,
        total: 300,
        type: CreditTransactionType.creditsPurchase,
    },
    {
        date: '2024-05-19',
        name: 'Germany Weather',
        userName: 'Murat Mutlu',
        responses: 50,
        fulfilled: 50,
        minutes: 1,
        total: 500,
        type: CreditTransactionType.recruitmentOrder,
    },
    {
        date: '2024-05-20',
        name: 'Refund',
        userName: 'Ballpark',
        responses: 0,
        minutes: 0,
        total: 60,
        type: CreditTransactionType.refund,
    },
];
const CreditsStats = {
    currentBalance: 20433,
    minutesUsedLast30Days: 272,
    totalMinutesUsed: 593,
    totalParticipants: 292,
};
const creditsStatsContainerClasses = [
    // Base styles
    'w-full',
    'rounded-xl',
    'shadow-sm',
    'ring-1',
    'basis-auto',
    'shrink-0',
    // Responsive grid layout
    'grid',
    'ring-gray-600/10',
    'grid-cols-1',
    'sm:grid-cols-2',
    'lg:grid-cols-4',
    'gap-px',
    // Hacky borders between grid items. I wish there was a native solution for this, but alas…
    // Horizontal borders
    'overflow-hidden',
    '*:relative',
    "*:before:content-['']",
    '*:before:absolute',
    '*:before:z-10',
    '*:before:bg-gray-600/10',
    '*:before:-top-px',
    '*:before:left-0',
    '*:before:w-full',
    '*:before:h-px',
    // Vertical borders
    "*:after:content-['']",
    '*:after:absolute',
    '*:after:z-10',
    '*:after:bg-gray-600/10',
    '*:after:-right-px',
    '*:after:bottom-0',
    '*:after:h-full',
    '*:after:w-px',
];
// Just a simple little number formatter
function formatNumber(number) {
    return new Intl.NumberFormat('en-US', {}).format(number);
}
export { RecruitmentOrdersSettingsPage };
