import { competitorPaidSearchTest, customerExitSurvey, customerFeedbackQuestionnaire, date, designFeedbackSurvey, designPreferenceTest, email, emailSubjectLineTesting, figmaCheckoutFlowTest, figmaInteractiveComponentTest, firstClickTest, firstImpressionsTest, fiveSecondTest, landingPagePreferenceTest, logoTestingSurvey, multipleChoiceQuestion, npsSurvey, onboardingFlowTest, openEndedQuestion, paperPrototypeTest, priceSensitivitySurvey, pricingPageTest, productMarketFitSurvey, productOnboardingSurvey, productUpdateAndChangelog, prototypeTest, qualityControlScreener, ratingScale, speakOutLoudScreener, systemUsabilityScale, taglineTest, userPersonaSurvey, websiteUsabilityTest, yesNoQuestion, } from './template-content';
import { CategoryGroupSlug, CategorySlug, } from './types';
export const TEMPLATES = [
    competitorPaidSearchTest,
    customerExitSurvey,
    customerFeedbackQuestionnaire,
    date,
    designFeedbackSurvey,
    designPreferenceTest,
    email,
    emailSubjectLineTesting,
    figmaCheckoutFlowTest,
    figmaInteractiveComponentTest,
    firstClickTest,
    firstImpressionsTest,
    fiveSecondTest,
    landingPagePreferenceTest,
    logoTestingSurvey,
    multipleChoiceQuestion,
    npsSurvey,
    onboardingFlowTest,
    openEndedQuestion,
    paperPrototypeTest,
    priceSensitivitySurvey,
    pricingPageTest,
    productMarketFitSurvey,
    productOnboardingSurvey,
    productUpdateAndChangelog,
    prototypeTest,
    qualityControlScreener,
    ratingScale,
    speakOutLoudScreener,
    systemUsabilityScale,
    taglineTest,
    userPersonaSurvey,
    websiteUsabilityTest,
    yesNoQuestion,
];
export const CATEGORIES = [
    {
        name: 'Popular',
        slug: CategorySlug.Popular,
        group: CategoryGroupSlug.Featured,
        title: 'Popular Templates for User Testing | Ballpark',
        metaDescription: 'See our most popular testing templates to get you up and running.',
        subheading: 'A sample of our most popular testing survey, design and product templates',
    },
    {
        name: 'The basics',
        slug: CategorySlug.Basics,
        group: CategoryGroupSlug.Featured,
        title: 'Getting Started Basics Testing Templates | Ballpark',
        metaDescription: "We've got the basics covered with these question type testing templates.",
        subheading: 'Get started with the testing basics and the types of questions and steps you include in Ballpark',
    },
    {
        name: 'Design and product',
        slug: CategorySlug.DesignAndProduct,
        group: CategoryGroupSlug.Departments,
        title: 'Templates for Design and Product Testing | Ballpark',
        metaDescription: 'Browse our test templates for design and product research including surveys on product market fit and usability.',
        subheading: 'Browse our test templates for design and product research including surveys on product market fit and usability.',
    },
    {
        name: 'Research',
        slug: CategorySlug.Research,
        group: CategoryGroupSlug.Departments,
        title: 'Templates for Research | Ballpark',
        metaDescription: 'Get your research up and running with these templates covering a range of question types.',
        subheading: 'Get your research up and running with these templates covering a range of question types.',
    },
    {
        name: 'Marketing',
        slug: CategorySlug.Marketing,
        group: CategoryGroupSlug.Departments,
        title: 'Templates for Marketing Testing | Ballpark',
        metaDescription: 'Measure the effectiveness of your marketing ads, copy and pricing with these research templates.',
        subheading: 'Measure the effectiveness of your marketing ads, copy and pricing with these research templates.',
    },
    {
        name: 'Prototype testing',
        slug: CategorySlug.PrototypeTesting,
        group: CategoryGroupSlug.UseCases,
        title: 'Templates for Prototype Testing | Ballpark',
        metaDescription: 'Use our prototype testing templates to test your Marvel and Figma prototypes. Gather feedback on your designs including landing pages and wireframes.',
        subheading: 'Use our prototype testing templates to test your Marvel and Figma prototypes. Gather feedback on your designs including landing pages and wireframes.',
    },
    {
        name: 'Product feedback',
        slug: CategorySlug.ProductFeedback,
        group: CategoryGroupSlug.UseCases,
        title: 'Templates for Product Feedback | Ballpark',
        metaDescription: 'Start capturing product feedback with these test templates covering NPS, Product-Market fit and price sensitivity.',
        subheading: 'Start capturing product feedback with these test templates covering NPS, Product-Market fit and price sensitivity.',
    },
    {
        name: 'Brand and copy testing',
        slug: CategorySlug.BrandAndCopyTesting,
        group: CategoryGroupSlug.UseCases,
        title: 'Templates for Copy Testing | Ballpark',
        metaDescription: 'Understand what copy converts with our brand and copy testing templates and make improvements to your marketing assets.',
        subheading: 'Understand what copy converts with our brand and copy testing templates and make improvements to your marketing assets.',
    },
    {
        name: 'Video guides',
        slug: CategorySlug.VideoGuides,
        group: CategoryGroupSlug.UseCases,
        title: 'Templates with Video Guides | Ballpark',
        metaDescription: 'See how video guides can be used to create interactive demos, highlight new features and more with our templates.',
        subheading: 'See how video guides can be used to create interactive demos, highlight new features and more with our templates.',
    },
    {
        name: 'Survey',
        slug: CategorySlug.Survey,
        group: CategoryGroupSlug.UseCases,
        title: 'Templates for Surveys | Ballpark',
        metaDescription: 'Capture users insights on your product and designs with these range of surveys',
        subheading: 'Capture users insights on your product and designs with these range of surveys',
    },
    {
        name: 'Website testing',
        slug: CategorySlug.WebsiteTesting,
        group: CategoryGroupSlug.UseCases,
        title: 'Templates for Website testing | Ballpark',
        metaDescription: 'Test your marketing site or web app. Discover whether features, messaging, pricing or conversion are intuitive. Record feedback by video, audio or survey responses.',
        subheading: 'Capture users insights on your product and designs with these range of surveys',
    },
    {
        name: 'Customer Success',
        slug: CategorySlug.CustomerSuccess,
        group: CategoryGroupSlug.Departments,
        title: 'Templates for Customer Success Testing | Ballpark',
        metaDescription: 'Improve your customer lifecycle and satisfaction with our range of research templates',
        subheading: 'Improve your customer lifecycle and satisfaction with our range of research templates',
    },
    {
        name: 'Sales',
        slug: CategorySlug.Sales,
        group: CategoryGroupSlug.Departments,
        title: 'Templates for Sales | Ballpark',
        metaDescription: 'Improve lead engagement with our templates on interactive sales demos, lead follow up and product tours.',
        subheading: 'Improve lead engagement with our templates on interactive sales demos, lead follow up and product tours.',
    },
    {
        name: 'Screeners',
        slug: CategorySlug.Screeners,
        group: CategoryGroupSlug.UseCases,
        title: 'Templates for Screeners | Ballpark',
        metaDescription: 'Screeners help ensure high-quality data by filtering out unsuitable participants in surveys and research studies. By using specific questions to determine eligibility, screeners maintain the integrity of your data collection process. Enhance your research with targeted screeners on Ballpark.',
        subheading: 'Use screeners to ensure high-quality data by filtering out unsuitable participants, maintaining the integrity and reliability of your research results.',
    },
];
export const CATEGORYGROUPS = [
    {
        name: 'Featured',
        slug: CategoryGroupSlug.Featured,
    },
    {
        name: 'Use Case',
        slug: CategoryGroupSlug.UseCases,
    },
    {
        name: 'Department',
        slug: CategoryGroupSlug.Departments,
    },
];
